export const state = () => ({
  helpfulReviewIds: [],
  reviewReward: 0,
});

export const getters = {
  helpfulReviewIds: state => state.helpfulReviewIds,
  reviewReward: state => state.reviewReward,
};

export const actions = {
  // 나의 리뷰 목록 조회
  selectReviewList(_, { page, limit, isReview }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/review`, {
          params: {
            page,
            size: limit,
            reviewStatus: isReview ? 'written' : 'writable',
          },
        })
        .then(res => {
          // 데이터 모양 잡기
          // res.items.forEach(s => {
          //   s.schedule.product.id = s.schedule.productId;
          //   if (s.userProductReview) {
          //     if (s.userProductReview.images) {
          //       s.userProductReview.images =
          //         s.userProductReview.images.split(',');
          //     }
          //   }
          // });
          resolve(res);
        })
        .catch(reject);
    });
  },
  selectReview(_, { userScheduleId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(
          `${process.env.API_V3}/v3/user/review/user-schedule/${userScheduleId}`,
        )
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 나의 일반 스케줄 리뷰 등록
  createReview(_, { id, formData }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(
          `${process.env.API_V3}/v3/user/review/user-schedule/${id}`,
          formData,
          // {
          //   headers: {
          //     'Content-Type': 'multipart/form-data',
          //   },
          // },
        )
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 나의 일반 스케줄 리뷰 수정
  updateReview(_, { id, formData }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$put(
          `${process.env.API_V3}/v3/user/review/user-schedule/${id}`,
          formData,
        )
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 나의 일반 스케줄 리뷰 삭제
  deleteReview(_, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$delete(`${process.env.API_V3}/v3/user/review/user-schedule/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 유저가 도움이 됐어요 누른 리뷰 아이디 목록을 일반상품 & 구독상품 나눠서 들고옵니다
  getHelpfulReviewIds({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/review/help`)
        .then(res => {
          if (!res.error && res.data) {
            commit('SET_REVIEW_IDS', res.data);
          } else {
            console.error('오류 발생');
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  toggleThisReviewHelpful({ commit }, { reviewId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/review/${reviewId}/help`)
        .then(res => {
          if (!res.error && res.data && res.data.isHelpful !== undefined) {
            if (res.data.isHelpful) commit('ADD_REVIEW_ID', { reviewId });
            else commit('REMOVE_REVIEW_ID', { reviewId });
          } else {
            console.error('오류 발생');
          }

          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getReviewReward({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/review/receivable-reward`)
        .then(res => {
          commit('SET_REVIEW_REWARD', res.data?.estimateRewardMoney);
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
};

export const mutations = {
  SET_REVIEW_IDS(state, reviews) {
    state.helpfulReviewIds = reviews;
  },
  ADD_REVIEW_ID(state, { reviewId }) {
    if (!state.helpfulReviewIds.includes(reviewId))
      state.helpfulReviewIds.push(reviewId);
  },
  REMOVE_REVIEW_ID(state, { reviewId }) {
    const currentIdx = state.helpfulReviewIds.findIndex(i => i === reviewId);
    if (currentIdx > -1) {
      state.helpfulReviewIds.splice(currentIdx, 1);
    }
  },
  RESET_HELPFUL_REVIEW_IDS(state) {
    state.helpfulReviewIds = [];
  },
  SET_REVIEW_REWARD(state, reward) {
    state.reviewReward = reward;
  },
};
