import { convertAmplitudeClassType } from '@/utils/classType.js';
import { SHA256 } from '@/utils/hash';
import { getIsApp } from '@/utils/localStorageUtils.js';
import { datadogRum } from '@datadog/browser-rum';
import Vue from 'vue';

// 채널톡 부트 후 보여줘야하는 페이지의 PATH 지정 (디폴트를 hide로 잡음)
const _showChannelIOButtonRoutePathList = ['/', '/schedule'];

const _initChannelIOBootOption = () => ({
  pluginKey: process.env.CHT_PLUGIN_KEY,
  hideChannelButtonOnBoot: true, // 부트 후 늘 숨기기 설정
  zIndex: 100,
  hidePopup: false, // 팝업창 노출 유무
});

/* eslint-disable */
export default ({ $config: { PACKAGE_VERSION }, $auth, route }) => {
  // 채널톡 부트 함수
  const _bootChannelIO = () => {
    const channelIOBootObject = _initChannelIOBootOption();

    if ($auth.loggedIn) {
      channelIOBootObject.memberId = $auth.user.id;
    }

    // 부트시 query에 isApp이 있거나 로컬스토리지에 isApp이 있다면 팝업이 뜨지 않게 합니다
    if (route.query?.isApp || !!getIsApp()) {
      channelIOBootObject.hidePopup = true;
    }

    // 부트시 특정 페이지에서는 hide옵션을 풀어줍니다
    if (_showChannelIOButtonRoutePathList.includes(route.path)) {
      channelIOBootObject.hideChannelButtonOnBoot = false;
    }

    /**
     * callback으로
     * 부트 실패시 (error객체, null)
     * 부트 성공시 (null, 유저객체)
     */
    ChannelIO('boot', channelIOBootObject, error => {
      if (error) {
        console.error(error);
      }
    });
  };

  const _updateChannelIOUser = ({ properties }) => {
    // updateUser에는 키값에 공백문자와 특수문자(<>.$)는 사용이 불가능해서 걸러줍니다.
    const channelIOProperties = Object.entries(properties).reduce(
      (chObj, props) => {
        chObj[props[0].replaceAll(/[<, >, $, \s]/g, '_')] = props[1];
        return chObj;
      },
      {},
    );

    const channelIOUserObject = {
      profile: {
        name: $auth.user.name,
        mobileNumber: $auth.user.phone,
        email: $auth.user.email,
        createdAt: $auth.user.createAt
          ? new Date($auth.user.createAt)
          : undefined,
        ...$auth.user.meta,
        ...channelIOProperties,
      },
    };

    /**
     * callback으로
     * 부트 실패시 (error객체, null)
     * 부트 성공시 (null, 유저객체)
     */
    ChannelIO('updateUser', channelIOUserObject, error => {
      if (error) {
        console.error(error);
      }
    });
  };

  // init
  const sourceInit = () => {
    return new Promise(resolve => {
      if (Vue.__sdk_init__) {
        resolve();
      } else {
        // teacher에서 아이프레임에 씌워서 특정 페이지를 띄우는 경우 from=teacher 쿼리가 붙어와
        // 이때는 로그 관련 작업을 하지 않습니다.
        const urlQuery = new URLSearchParams(window.location.search);
        if (urlQuery.has('from') && urlQuery.get('from') === 'teacher') {
          return;
        }

        // fb pixel
        fbq('init', process.env.FB_PIXEL_KEY);
        // gtag
        gtag('js', new Date());
        gtag('config', process.env.GA_KEY);
        // ga
        ga('create', process.env.GA_KEY, 'auto');
        // amplitude
        amplitude.getInstance().init(process.env.AMPLITUDE_KEY, null, {
          includeGclid: true,
          includeUtm: true,
          includeReferrer: true,
          saveParamsReferrerOncePerSession: false,
        });
        amplitude.getInstance().setVersionName(PACKAGE_VERSION);

        // 채널톡
        _bootChannelIO();

        datadogRum.init({
          applicationId: '0f0d0b57-649f-4d17-a502-cc7d1a744e93',
          clientToken: 'pubd78c0ca9fb82ac2d5d4d293ec4cf8246',
          site: 'datadoghq.com',
          service: 'kids-web',
          env: process.env.MODE,
          // Specify a version number to identify the deployed version of your application in Datadog
          version: PACKAGE_VERSION,
          sampleRate: 100,
          sessionReplaySampleRate: 100,
          trackInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
          enableExperimentalFeatures: ['clickmap'],
        });

        Vue.__sdk_init__ = true;
        resolve();
      }
    });
  };

  if (!Vue.__sdk_init__) {
    sourceInit();
  }

  Vue.mixin({
    mounted() {
      // 컴포넌트 자체 훅 이전에 호출됨
      if (!Vue.__sdk_init__) {
        sourceInit();
      }
    },
    // you can use these methods anywhere in components or pages
    methods: {
      async removeUserIdAmplitude() {
        if (!Vue.__sdk_init__) await sourceInit();
        amplitude.getInstance().setUserId(null);
        // amplitude.getInstance().regenerateDeviceId();
      },
      // query에서 정해진 utm종류가 있다면 해당 값을 받아와 log에 활용할 수 있게 가공하는 함수
      getUtmQuery() {
        const query = this.$route.query;

        const returnUtmQueryObj = Object.entries(query).reduce((acc, cur) => {
          const [key, value] = cur;

          if (
            [
              'utm_source',
              'utm_campaign',
              'utm_content',
              'utm_medium',
              'utm_term',
            ].includes(key)
          )
            acc[key] = value;

          return acc;
        }, {});

        return returnUtmQueryObj;
      },
      // amplitude setOnce를 이용해 한 번 만 저장
      async setAmplitudeUserPropertiesOnce(properties) {
        if (!Vue.__sdk_init__) await sourceInit();

        if (typeof properties !== 'object') {
          return;
        }

        for (const [key, value] of Object.entries(properties)) {
          const identify = new amplitude.Identify().setOnce(key, value);
          amplitude.getInstance().identify(identify);
        }
      },
      async setUserProperties(properties) {
        if (!Vue.__sdk_init__) await sourceInit();

        amplitude.getInstance().setUserProperties(properties);
        if (this.$auth.loggedIn) {
          amplitude.getInstance().setUserId(this.$auth.user.id);
          fbq('init', process.env.FB_PIXEL_KEY, {
            external_id: SHA256(this.$auth.user.id.toString()),
            em: SHA256(this.$auth.user.email),
          });

          // 채널톡 유저 update
          _updateChannelIOUser({ properties });

          datadogRum.startSessionReplayRecording();
          datadogRum.setUser({
            id: this.$auth.user.id,
            name: this.$auth.user.name,
            email: this.$auth.user.email,
          });
        }
      },
      // 로깅 이벤트 리팩토링용 신규버전
      async logUserCustomEvent(eventName, properties) {
        if (properties?.class_type) {
          properties.class_type = convertAmplitudeClassType(
            properties.class_type,
          );
        }

        if (!Vue.__sdk_init__) await sourceInit();

        const timeStamp = Math.floor(new Date().getTime() / 1000);

        ga('send', {
          hitType: 'event',
          eventCategory: 'event',
          eventAction: eventName,
          eventLabel: eventName,
          ...properties,
        });

        amplitude.getInstance().logEvent(eventName, properties);

        fbq('trackCustom', eventName, properties);

        ChannelIO('track', eventName, properties);
      },
      async logUserEvent(eventName, properties) {
        // console.log('앰플리튜드 로그 시작 --------');
        // console.log(eventName, properties);
        // console.log('앰플리튜드 로그 끝  --------');

        if (properties?.class_type) {
          properties.class_type = convertAmplitudeClassType(
            properties.class_type,
          );
        }

        if (!Vue.__sdk_init__) await sourceInit();

        const timeStamp = Math.floor(new Date().getTime() / 1000);

        ga('send', {
          hitType: 'event',
          eventCategory: 'event',
          eventAction: eventName,
          eventLabel: eventName,
          ...properties,
        });

        amplitude.getInstance().logEvent(eventName, properties);

        fbq('trackCustom', eventName, properties);

        ChannelIO('track', eventName, properties);
      },
      async hideChannelTalkBtn() {
        if (!Vue.__sdk_init__) await sourceInit();
        ChannelIO('hideChannelButton');
      },
      async showChannelTalkBtn() {
        if (!Vue.__sdk_init__) await sourceInit();
        ChannelIO('showChannelButton');
      },
      // channel-talk shutdown and reboot
      async restartChannelTalk() {
        if (!Vue.__sdk_init__) await sourceInit();
        ChannelIO('shutdown');
        _bootChannelIO();
      },
    },
  });
};
