import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6ab4e6df = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _9326c1d6 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _048730be = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _51f742d3 = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _bd028058 = () => interopDefault(import('../pages/introduction.vue' /* webpackChunkName: "pages/introduction" */))
const _f2ca746e = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _435880e9 = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _5e652215 = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _639f9208 = () => interopDefault(import('../pages/private/index.vue' /* webpackChunkName: "pages/private/index" */))
const _3ed8f130 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _48a50698 = () => interopDefault(import('../pages/psych/index.vue' /* webpackChunkName: "pages/psych/index" */))
const _fc9cea72 = () => interopDefault(import('../pages/psyche/index.vue' /* webpackChunkName: "pages/psyche/index" */))
const _1d654c22 = () => interopDefault(import('../pages/ranking/index.vue' /* webpackChunkName: "pages/ranking/index" */))
const _6f97b376 = () => interopDefault(import('../pages/request.vue' /* webpackChunkName: "pages/request" */))
const _1c239075 = () => interopDefault(import('../pages/roadmap/index.vue' /* webpackChunkName: "pages/roadmap/index" */))
const _641bba94 = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _bd00a636 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _a8309aca = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _39f5f0ca = () => interopDefault(import('../pages/auth/leave.vue' /* webpackChunkName: "pages/auth/leave" */))
const _fca19200 = () => interopDefault(import('../pages/auth/leave-success.vue' /* webpackChunkName: "pages/auth/leave-success" */))
const _2a7fd7d6 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _5948ed54 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _8d40581a = () => interopDefault(import('../pages/card/fail.vue' /* webpackChunkName: "pages/card/fail" */))
const _0c9c5b9c = () => interopDefault(import('../pages/card/result.vue' /* webpackChunkName: "pages/card/result" */))
const _5862b36b = () => interopDefault(import('../pages/my/alarm/index.vue' /* webpackChunkName: "pages/my/alarm/index" */))
const _fc94161e = () => interopDefault(import('../pages/my/authCode/index.vue' /* webpackChunkName: "pages/my/authCode/index" */))
const _2225791f = () => interopDefault(import('../pages/my/coupon.vue' /* webpackChunkName: "pages/my/coupon" */))
const _52bf1c71 = () => interopDefault(import('../pages/my/favorites/index.vue' /* webpackChunkName: "pages/my/favorites/index" */))
const _11e6e46c = () => interopDefault(import('../pages/my/friends.vue' /* webpackChunkName: "pages/my/friends" */))
const _30c149cc = () => interopDefault(import('../pages/my/money/index.vue' /* webpackChunkName: "pages/my/money/index" */))
const _44df2fb0 = () => interopDefault(import('../pages/my/order/index.vue' /* webpackChunkName: "pages/my/order/index" */))
const _d98abdfa = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _cc51b616 = () => interopDefault(import('../pages/my/reviews/index.vue' /* webpackChunkName: "pages/my/reviews/index" */))
const _e4deb04e = () => interopDefault(import('../pages/my/subscription/index.vue' /* webpackChunkName: "pages/my/subscription/index" */))
const _6b09f505 = () => interopDefault(import('../pages/my/voucher.vue' /* webpackChunkName: "pages/my/voucher" */))
const _0f1032c1 = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _b6749500 = () => interopDefault(import('../pages/payment/result.vue' /* webpackChunkName: "pages/payment/result" */))
const _df1f8d1c = () => interopDefault(import('../pages/private/banner.vue' /* webpackChunkName: "pages/private/banner" */))
const _cd9e5282 = () => interopDefault(import('../pages/private/commonTextAreaField.vue' /* webpackChunkName: "pages/private/commonTextAreaField" */))
const _3395697f = () => interopDefault(import('../pages/private/complete.vue' /* webpackChunkName: "pages/private/complete" */))
const _543d8e5f = () => interopDefault(import('../pages/private/preGuide.vue' /* webpackChunkName: "pages/private/preGuide" */))
const _b5c40c94 = () => interopDefault(import('../pages/private/privateRequestForm.vue' /* webpackChunkName: "pages/private/privateRequestForm" */))
const _75c9550a = () => interopDefault(import('../pages/private/processGuideModal.vue' /* webpackChunkName: "pages/private/processGuideModal" */))
const _c48844cc = () => interopDefault(import('../pages/product/suggest.vue' /* webpackChunkName: "pages/product/suggest" */))
const _6ead9694 = () => interopDefault(import('../pages/psyche/guidance/index.vue' /* webpackChunkName: "pages/psyche/guidance/index" */))
const _3e12a2ec = () => interopDefault(import('../pages/psyche/huno/index.vue' /* webpackChunkName: "pages/psyche/huno/index" */))
const _7ccd7a2f = () => interopDefault(import('../pages/teacher/search.vue' /* webpackChunkName: "pages/teacher/search" */))
const _745487f4 = () => interopDefault(import('../pages/my/money/fail.vue' /* webpackChunkName: "pages/my/money/fail" */))
const _72261feb = () => interopDefault(import('../pages/my/money/fill.vue' /* webpackChunkName: "pages/my/money/fill" */))
const _7f63fcf6 = () => interopDefault(import('../pages/my/money/result.vue' /* webpackChunkName: "pages/my/money/result" */))
const _79cdf1f2 = () => interopDefault(import('../pages/my/money/withdraw.vue' /* webpackChunkName: "pages/my/money/withdraw" */))
const _73aa3788 = () => interopDefault(import('../pages/my/money/withdraw-result.vue' /* webpackChunkName: "pages/my/money/withdraw-result" */))
const _5f732d97 = () => interopDefault(import('../pages/my/order/cancel/index.vue' /* webpackChunkName: "pages/my/order/cancel/index" */))
const _849f8f66 = () => interopDefault(import('../pages/my/profile/child.vue' /* webpackChunkName: "pages/my/profile/child" */))
const _27a3d674 = () => interopDefault(import('../pages/my/subscription/cancel/index.vue' /* webpackChunkName: "pages/my/subscription/cancel/index" */))
const _6f6e1f07 = () => interopDefault(import('../pages/my/subscription/confirm.vue' /* webpackChunkName: "pages/my/subscription/confirm" */))
const _f226b5d8 = () => interopDefault(import('../pages/schedule/cancel/result.vue' /* webpackChunkName: "pages/schedule/cancel/result" */))
const _7e7ddaf0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _55fabd60 = () => interopDefault(import('../pages/my/reviews/_id/index.vue' /* webpackChunkName: "pages/my/reviews/_id/index" */))
const _eec59d8c = () => interopDefault(import('../pages/product/curation/_id.vue' /* webpackChunkName: "pages/product/curation/_id" */))
const _721ad924 = () => interopDefault(import('../pages/psyche/guidance/_slug.vue' /* webpackChunkName: "pages/psyche/guidance/_slug" */))
const _3c5c01a4 = () => interopDefault(import('../pages/psyche/huno/_slug.vue' /* webpackChunkName: "pages/psyche/huno/_slug" */))
const _1d5ee7ff = () => interopDefault(import('../pages/my/reviews/_id/result.vue' /* webpackChunkName: "pages/my/reviews/_id/result" */))
const _75f24a1a = () => interopDefault(import('../pages/my/order/_type/_id.vue' /* webpackChunkName: "pages/my/order/_type/_id" */))
const _68fe4597 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _5040a18b = () => interopDefault(import('../pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _8f5ad864 = () => interopDefault(import('../pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _50e2b486 = () => interopDefault(import('../pages/notice/_id.vue' /* webpackChunkName: "pages/notice/_id" */))
const _aaa77860 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _4a0aae53 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _f5aeb172 = () => interopDefault(import('../pages/product/_id/payment.vue' /* webpackChunkName: "pages/product/_id/payment" */))
const _24f64a4b = () => interopDefault(import('../pages/product/_id/subscribe.vue' /* webpackChunkName: "pages/product/_id/subscribe" */))
const _d6ec02ec = () => interopDefault(import('../pages/product/_id/notice/_noticeId.vue' /* webpackChunkName: "pages/product/_id/notice/_noticeId" */))
const _4c124928 = () => interopDefault(import('../pages/psych/_slug.vue' /* webpackChunkName: "pages/psych/_slug" */))
const _6655a7c6 = () => interopDefault(import('../pages/roadmap/_id.vue' /* webpackChunkName: "pages/roadmap/_id" */))
const _85b52e40 = () => interopDefault(import('../pages/roadmap/_id/index.vue' /* webpackChunkName: "pages/roadmap/_id/index" */))
const _bb0ff3a2 = () => interopDefault(import('../pages/schedule/_hash.vue' /* webpackChunkName: "pages/schedule/_hash" */))
const _7e03d061 = () => interopDefault(import('../pages/schedule/_hash/detail.vue' /* webpackChunkName: "pages/schedule/_hash/detail" */))
const _9130c07a = () => interopDefault(import('../pages/teacher/_id.vue' /* webpackChunkName: "pages/teacher/_id" */))
const _2187ca4e = () => interopDefault(import('../pages/coupon/_id/usable-product.vue' /* webpackChunkName: "pages/coupon/_id/usable-product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _6ab4e6df,
    name: "blog"
  }, {
    path: "/chat",
    component: _9326c1d6,
    name: "chat"
  }, {
    path: "/error",
    component: _048730be,
    name: "error"
  }, {
    path: "/event",
    component: _51f742d3,
    name: "event"
  }, {
    path: "/introduction",
    component: _bd028058,
    name: "introduction"
  }, {
    path: "/maintenance",
    component: _f2ca746e,
    name: "maintenance"
  }, {
    path: "/my",
    component: _435880e9,
    name: "my"
  }, {
    path: "/notice",
    component: _5e652215,
    name: "notice"
  }, {
    path: "/private",
    component: _639f9208,
    name: "private"
  }, {
    path: "/product",
    component: _3ed8f130,
    name: "product"
  }, {
    path: "/psych",
    component: _48a50698,
    name: "psych"
  }, {
    path: "/psyche",
    component: _fc9cea72,
    name: "psyche"
  }, {
    path: "/ranking",
    component: _1d654c22,
    name: "ranking"
  }, {
    path: "/request",
    component: _6f97b376,
    name: "request"
  }, {
    path: "/roadmap",
    component: _1c239075,
    name: "roadmap"
  }, {
    path: "/schedule",
    component: _641bba94,
    name: "schedule"
  }, {
    path: "/search",
    component: _bd00a636,
    name: "search"
  }, {
    path: "/teacher",
    component: _a8309aca,
    name: "teacher"
  }, {
    path: "/auth/leave",
    component: _39f5f0ca,
    name: "auth-leave"
  }, {
    path: "/auth/leave-success",
    component: _fca19200,
    name: "auth-leave-success"
  }, {
    path: "/auth/signup",
    component: _2a7fd7d6,
    name: "auth-signup"
  }, {
    path: "/auth/success",
    component: _5948ed54,
    name: "auth-success"
  }, {
    path: "/card/fail",
    component: _8d40581a,
    name: "card-fail"
  }, {
    path: "/card/result",
    component: _0c9c5b9c,
    name: "card-result"
  }, {
    path: "/my/alarm",
    component: _5862b36b,
    name: "my-alarm"
  }, {
    path: "/my/authCode",
    component: _fc94161e,
    name: "my-authCode"
  }, {
    path: "/my/coupon",
    component: _2225791f,
    name: "my-coupon"
  }, {
    path: "/my/favorites",
    component: _52bf1c71,
    name: "my-favorites"
  }, {
    path: "/my/friends",
    component: _11e6e46c,
    name: "my-friends"
  }, {
    path: "/my/money",
    component: _30c149cc,
    name: "my-money"
  }, {
    path: "/my/order",
    component: _44df2fb0,
    name: "my-order"
  }, {
    path: "/my/profile",
    component: _d98abdfa,
    name: "my-profile"
  }, {
    path: "/my/reviews",
    component: _cc51b616,
    name: "my-reviews"
  }, {
    path: "/my/subscription",
    component: _e4deb04e,
    name: "my-subscription"
  }, {
    path: "/my/voucher",
    component: _6b09f505,
    name: "my-voucher"
  }, {
    path: "/payment/fail",
    component: _0f1032c1,
    name: "payment-fail"
  }, {
    path: "/payment/result",
    component: _b6749500,
    name: "payment-result"
  }, {
    path: "/private/banner",
    component: _df1f8d1c,
    name: "private-banner"
  }, {
    path: "/private/commonTextAreaField",
    component: _cd9e5282,
    name: "private-commonTextAreaField"
  }, {
    path: "/private/complete",
    component: _3395697f,
    name: "private-complete"
  }, {
    path: "/private/preGuide",
    component: _543d8e5f,
    name: "private-preGuide"
  }, {
    path: "/private/privateRequestForm",
    component: _b5c40c94,
    name: "private-privateRequestForm"
  }, {
    path: "/private/processGuideModal",
    component: _75c9550a,
    name: "private-processGuideModal"
  }, {
    path: "/product/suggest",
    component: _c48844cc,
    name: "product-suggest"
  }, {
    path: "/psyche/guidance",
    component: _6ead9694,
    name: "psyche-guidance"
  }, {
    path: "/psyche/huno",
    component: _3e12a2ec,
    name: "psyche-huno"
  }, {
    path: "/teacher/search",
    component: _7ccd7a2f,
    name: "teacher-search"
  }, {
    path: "/my/money/fail",
    component: _745487f4,
    name: "my-money-fail"
  }, {
    path: "/my/money/fill",
    component: _72261feb,
    name: "my-money-fill"
  }, {
    path: "/my/money/result",
    component: _7f63fcf6,
    name: "my-money-result"
  }, {
    path: "/my/money/withdraw",
    component: _79cdf1f2,
    name: "my-money-withdraw"
  }, {
    path: "/my/money/withdraw-result",
    component: _73aa3788,
    name: "my-money-withdraw-result"
  }, {
    path: "/my/order/cancel",
    component: _5f732d97,
    name: "my-order-cancel"
  }, {
    path: "/my/profile/child",
    component: _849f8f66,
    name: "my-profile-child"
  }, {
    path: "/my/subscription/cancel",
    component: _27a3d674,
    name: "my-subscription-cancel"
  }, {
    path: "/my/subscription/confirm",
    component: _6f6e1f07,
    name: "my-subscription-confirm"
  }, {
    path: "/schedule/cancel/result",
    component: _f226b5d8,
    name: "schedule-cancel-result"
  }, {
    path: "/",
    component: _7e7ddaf0,
    name: "index"
  }, {
    path: "/my/reviews/:id",
    component: _55fabd60,
    name: "my-reviews-id"
  }, {
    path: "/product/curation/:id?",
    component: _eec59d8c,
    name: "product-curation-id"
  }, {
    path: "/psyche/guidance/:slug",
    component: _721ad924,
    name: "psyche-guidance-slug"
  }, {
    path: "/psyche/huno/:slug",
    component: _3c5c01a4,
    name: "psyche-huno-slug"
  }, {
    path: "/my/reviews/:id/result",
    component: _1d5ee7ff,
    name: "my-reviews-id-result"
  }, {
    path: "/my/order/:type/:id?",
    component: _75f24a1a,
    name: "my-order-type-id"
  }, {
    path: "/blog/:slug",
    component: _68fe4597,
    name: "blog-slug"
  }, {
    path: "/event/:slug",
    component: _5040a18b,
    children: [{
      path: "",
      component: _8f5ad864,
      name: "event-slug"
    }]
  }, {
    path: "/notice/:id",
    component: _50e2b486,
    name: "notice-id"
  }, {
    path: "/product/:id",
    component: _aaa77860,
    children: [{
      path: "",
      component: _4a0aae53,
      name: "product-id"
    }, {
      path: "payment",
      component: _f5aeb172,
      name: "product-id-payment"
    }, {
      path: "subscribe",
      component: _24f64a4b,
      name: "product-id-subscribe"
    }, {
      path: "notice/:noticeId?",
      component: _d6ec02ec,
      name: "product-id-notice-noticeId"
    }]
  }, {
    path: "/psych/:slug",
    component: _4c124928,
    name: "psych-slug"
  }, {
    path: "/roadmap/:id",
    component: _6655a7c6,
    children: [{
      path: "",
      component: _85b52e40,
      name: "roadmap-id"
    }]
  }, {
    path: "/schedule/:hash",
    component: _bb0ff3a2,
    name: "schedule-hash",
    children: [{
      path: "detail",
      component: _7e03d061,
      name: "schedule-hash-detail"
    }]
  }, {
    path: "/teacher/:id",
    component: _9130c07a,
    name: "teacher-id"
  }, {
    path: "/coupon/:id?/usable-product",
    component: _2187ca4e,
    name: "coupon-id-usable-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
