
/**
 * @description
 * 홈 화면 > 재능 / 학습 스위칭 버튼
 */

import { CLICK_HOMETYPE } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { getLastHomeType, setLastHomeType } from '@/utils/cookieUtils';

export default {
  name: 'PageSwitch',
  data() {
    return {
      isMoving: false,
      educationIcon: require('@/assets/img/home/education.svg'),
      talentIcon: require('@/assets/img/home/talent.svg'),

      // 타이머 변수 저장
      timer: null,
    };
  },
  computed: {
    isTalentPage: ({ $route }) => {
      const lastHomeType = getLastHomeType();
      return (
        ($route?.query?.tab === undefined && lastHomeType !== 'education') ||
        $route?.query?.tab === 'talent'
      );
    },
  },
  methods: {
    handlePageSwitch() {
      // 움직이는 중이라면 얼리리턴
      if (this.isMoving) return;

      this.isMoving = true;

      this.logSwitchEvent();

      setLastHomeType(this.isTalentPage ? 'education' : 'talent');
      this.$router.push({
        name: this.$route.name,
        query: { tab: this.isTalentPage ? 'education' : 'talent' },
      });

      // animation 완료 시점(0.3초) 후에 다시 동작 가능하게 해줍니다.
      setTimeout(() => {
        this.isMoving = false;
      }, 300);
    },
    logSwitchEvent() {
      this.logUserCustomEvent(CLICK_HOMETYPE);
    },
  },
};
