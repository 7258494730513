export const ScheduleItem = () => import('../../components/ScheduleItem.vue' /* webpackChunkName: "components/schedule-item" */).then(c => wrapFunctional(c.default || c))
export const VerticalProductItem = () => import('../../components/VerticalProductItem.vue' /* webpackChunkName: "components/vertical-product-item" */).then(c => wrapFunctional(c.default || c))
export const CalendarMonthly = () => import('../../components/Calendar/Monthly.vue' /* webpackChunkName: "components/calendar-monthly" */).then(c => wrapFunctional(c.default || c))
export const BlogCategory = () => import('../../components/blog/BlogCategory.vue' /* webpackChunkName: "components/blog-category" */).then(c => wrapFunctional(c.default || c))
export const BlogGrid = () => import('../../components/blog/BlogGrid.vue' /* webpackChunkName: "components/blog-grid" */).then(c => wrapFunctional(c.default || c))
export const BlogItem = () => import('../../components/blog/BlogItem.vue' /* webpackChunkName: "components/blog-item" */).then(c => wrapFunctional(c.default || c))
export const BlogJoin = () => import('../../components/blog/BlogJoin.vue' /* webpackChunkName: "components/blog-join" */).then(c => wrapFunctional(c.default || c))
export const BlogLineBanner = () => import('../../components/blog/BlogLineBanner.vue' /* webpackChunkName: "components/blog-line-banner" */).then(c => wrapFunctional(c.default || c))
export const BlogListStatus = () => import('../../components/blog/BlogListStatus.vue' /* webpackChunkName: "components/blog-list-status" */).then(c => wrapFunctional(c.default || c))
export const BlogShare = () => import('../../components/blog/BlogShare.vue' /* webpackChunkName: "components/blog-share" */).then(c => wrapFunctional(c.default || c))
export const ChatAdminMessageBox = () => import('../../components/chat/AdminMessageBox.vue' /* webpackChunkName: "components/chat-admin-message-box" */).then(c => wrapFunctional(c.default || c))
export const ChatChannel = () => import('../../components/chat/Channel.vue' /* webpackChunkName: "components/chat-channel" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelHeader = () => import('../../components/chat/ChannelHeader.vue' /* webpackChunkName: "components/chat-channel-header" */).then(c => wrapFunctional(c.default || c))
export const ChatClassItem = () => import('../../components/chat/ClassItem.vue' /* webpackChunkName: "components/chat-class-item" */).then(c => wrapFunctional(c.default || c))
export const ChatClassList = () => import('../../components/chat/ClassList.vue' /* webpackChunkName: "components/chat-class-list" */).then(c => wrapFunctional(c.default || c))
export const ChatClassListModal = () => import('../../components/chat/ClassListModal.vue' /* webpackChunkName: "components/chat-class-list-modal" */).then(c => wrapFunctional(c.default || c))
export const ChatFileMessageContent = () => import('../../components/chat/FileMessageContent.vue' /* webpackChunkName: "components/chat-file-message-content" */).then(c => wrapFunctional(c.default || c))
export const ChatGuideBox = () => import('../../components/chat/GuideBox.vue' /* webpackChunkName: "components/chat-guide-box" */).then(c => wrapFunctional(c.default || c))
export const ChatGuideBoxKeepChatData = () => import('../../components/chat/GuideBoxKeepChatData.vue' /* webpackChunkName: "components/chat-guide-box-keep-chat-data" */).then(c => wrapFunctional(c.default || c))
export const ChatGuideBoxSafeComm = () => import('../../components/chat/GuideBoxSafeComm.vue' /* webpackChunkName: "components/chat-guide-box-safe-comm" */).then(c => wrapFunctional(c.default || c))
export const ChatGuideDayLine = () => import('../../components/chat/GuideDayLine.vue' /* webpackChunkName: "components/chat-guide-day-line" */).then(c => wrapFunctional(c.default || c))
export const ChatGuideTeacherAllow = () => import('../../components/chat/GuideTeacherAllow.vue' /* webpackChunkName: "components/chat-guide-teacher-allow" */).then(c => wrapFunctional(c.default || c))
export const ChatGuideTeacherNotAllow = () => import('../../components/chat/GuideTeacherNotAllow.vue' /* webpackChunkName: "components/chat-guide-teacher-not-allow" */).then(c => wrapFunctional(c.default || c))
export const ChatLoading = () => import('../../components/chat/Loading.vue' /* webpackChunkName: "components/chat-loading" */).then(c => wrapFunctional(c.default || c))
export const ChatMessageFunc = () => import('../../components/chat/MessageFunc.vue' /* webpackChunkName: "components/chat-message-func" */).then(c => wrapFunctional(c.default || c))
export const ChatMessageMenuModal = () => import('../../components/chat/MessageMenuModal.vue' /* webpackChunkName: "components/chat-message-menu-modal" */).then(c => wrapFunctional(c.default || c))
export const ChatMessageReadStatus = () => import('../../components/chat/MessageReadStatus.vue' /* webpackChunkName: "components/chat-message-read-status" */).then(c => wrapFunctional(c.default || c))
export const ChatMessageStateDisplay = () => import('../../components/chat/MessageStateDisplay.vue' /* webpackChunkName: "components/chat-message-state-display" */).then(c => wrapFunctional(c.default || c))
export const ChatNewMessageCome = () => import('../../components/chat/NewMessageCome.vue' /* webpackChunkName: "components/chat-new-message-come" */).then(c => wrapFunctional(c.default || c))
export const ChatPreviewMessage = () => import('../../components/chat/PreviewMessage.vue' /* webpackChunkName: "components/chat-preview-message" */).then(c => wrapFunctional(c.default || c))
export const ChatReportModal = () => import('../../components/chat/ReportModal.vue' /* webpackChunkName: "components/chat-report-modal" */).then(c => wrapFunctional(c.default || c))
export const ChatScheduleItem = () => import('../../components/chat/ScheduleItem.vue' /* webpackChunkName: "components/chat-schedule-item" */).then(c => wrapFunctional(c.default || c))
export const ChatScheduleList = () => import('../../components/chat/ScheduleList.vue' /* webpackChunkName: "components/chat-schedule-list" */).then(c => wrapFunctional(c.default || c))
export const ChatTextMessage = () => import('../../components/chat/TextMessage.vue' /* webpackChunkName: "components/chat-text-message" */).then(c => wrapFunctional(c.default || c))
export const CommonAboutTeacherBox = () => import('../../components/common/AboutTeacherBox.vue' /* webpackChunkName: "components/common-about-teacher-box" */).then(c => wrapFunctional(c.default || c))
export const CommonAccordionSelectMenu = () => import('../../components/common/AccordionSelectMenu.vue' /* webpackChunkName: "components/common-accordion-select-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonAlert = () => import('../../components/common/Alert.vue' /* webpackChunkName: "components/common-alert" */).then(c => wrapFunctional(c.default || c))
export const CommonAlertBox = () => import('../../components/common/AlertBox.vue' /* webpackChunkName: "components/common-alert-box" */).then(c => wrapFunctional(c.default || c))
export const CommonAsyncModal = () => import('../../components/common/AsyncModal.vue' /* webpackChunkName: "components/common-async-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonFail = () => import('../../components/common/CommonFail.vue' /* webpackChunkName: "components/common-fail" */).then(c => wrapFunctional(c.default || c))
export const CommonFloatingTooltip = () => import('../../components/common/CommonFloatingTooltip.vue' /* webpackChunkName: "components/common-floating-tooltip" */).then(c => wrapFunctional(c.default || c))
export const CommonLikeCount = () => import('../../components/common/CommonLikeCount.vue' /* webpackChunkName: "components/common-like-count" */).then(c => wrapFunctional(c.default || c))
export const CommonModal = () => import('../../components/common/CommonModal.vue' /* webpackChunkName: "components/common-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonProfile = () => import('../../components/common/CommonProfile.vue' /* webpackChunkName: "components/common-profile" */).then(c => wrapFunctional(c.default || c))
export const CommonRangeSlider = () => import('../../components/common/CommonRangeSlider.vue' /* webpackChunkName: "components/common-range-slider" */).then(c => wrapFunctional(c.default || c))
export const CommonRating = () => import('../../components/common/CommonRating.vue' /* webpackChunkName: "components/common-rating" */).then(c => wrapFunctional(c.default || c))
export const CommonResult = () => import('../../components/common/CommonResult.vue' /* webpackChunkName: "components/common-result" */).then(c => wrapFunctional(c.default || c))
export const CommonConfirm = () => import('../../components/common/Confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => wrapFunctional(c.default || c))
export const CommonCurationProgressSection = () => import('../../components/common/CurationProgressSection.vue' /* webpackChunkName: "components/common-curation-progress-section" */).then(c => wrapFunctional(c.default || c))
export const CommonDivider = () => import('../../components/common/Divider.vue' /* webpackChunkName: "components/common-divider" */).then(c => wrapFunctional(c.default || c))
export const CommonIncreaseNumber = () => import('../../components/common/IncreaseNumber.vue' /* webpackChunkName: "components/common-increase-number" */).then(c => wrapFunctional(c.default || c))
export const CommonNewCommonResult = () => import('../../components/common/NewCommonResult.vue' /* webpackChunkName: "components/common-new-common-result" */).then(c => wrapFunctional(c.default || c))
export const CommonNoResult = () => import('../../components/common/NoResult.vue' /* webpackChunkName: "components/common-no-result" */).then(c => wrapFunctional(c.default || c))
export const CommonProgressSpinner = () => import('../../components/common/ProgressSpinner.vue' /* webpackChunkName: "components/common-progress-spinner" */).then(c => wrapFunctional(c.default || c))
export const CommonResizableTextarea = () => import('../../components/common/ResizableTextarea.vue' /* webpackChunkName: "components/common-resizable-textarea" */).then(c => wrapFunctional(c.default || c))
export const CommonReviewBannerSwiper = () => import('../../components/common/ReviewBannerSwiper.vue' /* webpackChunkName: "components/common-review-banner-swiper" */).then(c => wrapFunctional(c.default || c))
export const CommonScheduleDetailBox = () => import('../../components/common/ScheduleDetailBox.vue' /* webpackChunkName: "components/common-schedule-detail-box" */).then(c => wrapFunctional(c.default || c))
export const CommonSignupSuccessEventPopup = () => import('../../components/common/SignupSuccessEventPopup.vue' /* webpackChunkName: "components/common-signup-success-event-popup" */).then(c => wrapFunctional(c.default || c))
export const CommonTabBar = () => import('../../components/common/TabBar.vue' /* webpackChunkName: "components/common-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const CommonTextTabBar = () => import('../../components/common/TextTabBar.vue' /* webpackChunkName: "components/common-text-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const CommonVFilterDropdown = () => import('../../components/common/VFilterDropdown.vue' /* webpackChunkName: "components/common-v-filter-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CommonVInfoDropdown = () => import('../../components/common/VInfoDropdown.vue' /* webpackChunkName: "components/common-v-info-dropdown" */).then(c => wrapFunctional(c.default || c))
export const EventConfirmModal = () => import('../../components/event/EventConfirmModal.vue' /* webpackChunkName: "components/event-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const Introduction = () => import('../../components/introduction/Introduction.vue' /* webpackChunkName: "components/introduction" */).then(c => wrapFunctional(c.default || c))
export const IntroductionVision = () => import('../../components/introduction/Vision.vue' /* webpackChunkName: "components/introduction-vision" */).then(c => wrapFunctional(c.default || c))
export const LayoutAppDrawer = () => import('../../components/layout/AppDrawer.vue' /* webpackChunkName: "components/layout-app-drawer" */).then(c => wrapFunctional(c.default || c))
export const LayoutAppNavFooter = () => import('../../components/layout/AppNavFooter.vue' /* webpackChunkName: "components/layout-app-nav-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutPageLayout = () => import('../../components/layout/PageLayout.vue' /* webpackChunkName: "components/layout-page-layout" */).then(c => wrapFunctional(c.default || c))
export const MyPendingReviewItem = () => import('../../components/my/MyPendingReviewItem.vue' /* webpackChunkName: "components/my-pending-review-item" */).then(c => wrapFunctional(c.default || c))
export const MyReviewItem = () => import('../../components/my/MyReviewItem.vue' /* webpackChunkName: "components/my-review-item" */).then(c => wrapFunctional(c.default || c))
export const MyReviewReward = () => import('../../components/my/MyReviewReward.vue' /* webpackChunkName: "components/my-review-reward" */).then(c => wrapFunctional(c.default || c))
export const MyReviewPolicyIcon = () => import('../../components/my/ReviewPolicyIcon.vue' /* webpackChunkName: "components/my-review-policy-icon" */).then(c => wrapFunctional(c.default || c))
export const NewProductCheckList = () => import('../../components/new-product/CheckList.vue' /* webpackChunkName: "components/new-product-check-list" */).then(c => wrapFunctional(c.default || c))
export const NewProductDescription = () => import('../../components/new-product/Description.vue' /* webpackChunkName: "components/new-product-description" */).then(c => wrapFunctional(c.default || c))
export const NewProduct = () => import('../../components/new-product/NewProduct.vue' /* webpackChunkName: "components/new-product" */).then(c => wrapFunctional(c.default || c))
export const NewProductCourseList = () => import('../../components/new-product/ProductCourseList.vue' /* webpackChunkName: "components/new-product-course-list" */).then(c => wrapFunctional(c.default || c))
export const NewProductNavigator = () => import('../../components/new-product/ProductNavigator.vue' /* webpackChunkName: "components/new-product-navigator" */).then(c => wrapFunctional(c.default || c))
export const NewProductRecommend = () => import('../../components/new-product/ProductRecommend.vue' /* webpackChunkName: "components/new-product-recommend" */).then(c => wrapFunctional(c.default || c))
export const NewProductRoadmapBanner = () => import('../../components/new-product/RoadmapBanner.vue' /* webpackChunkName: "components/new-product-roadmap-banner" */).then(c => wrapFunctional(c.default || c))
export const PaymentCardModal = () => import('../../components/payment/CardModal.vue' /* webpackChunkName: "components/payment-card-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentClassPaymentMethod = () => import('../../components/payment/ClassPaymentMethod.vue' /* webpackChunkName: "components/payment-class-payment-method" */).then(c => wrapFunctional(c.default || c))
export const PaymentCoupon = () => import('../../components/payment/Coupon.vue' /* webpackChunkName: "components/payment-coupon" */).then(c => wrapFunctional(c.default || c))
export const PaymentCouponModal = () => import('../../components/payment/CouponModal.vue' /* webpackChunkName: "components/payment-coupon-modal" */).then(c => wrapFunctional(c.default || c))
export const PaymentCouponSelectModalCaller = () => import('../../components/payment/CouponSelectModalCaller.vue' /* webpackChunkName: "components/payment-coupon-select-modal-caller" */).then(c => wrapFunctional(c.default || c))
export const PaymentCouponTitle = () => import('../../components/payment/CouponTitle.vue' /* webpackChunkName: "components/payment-coupon-title" */).then(c => wrapFunctional(c.default || c))
export const PaymentMaxBenefitCouponApplyButton = () => import('../../components/payment/MaxBenefitCouponApplyButton.vue' /* webpackChunkName: "components/payment-max-benefit-coupon-apply-button" */).then(c => wrapFunctional(c.default || c))
export const PaymentAgreements = () => import('../../components/payment/PaymentAgreements.vue' /* webpackChunkName: "components/payment-agreements" */).then(c => wrapFunctional(c.default || c))
export const PaymentAmountTable = () => import('../../components/payment/PaymentAmountTable.vue' /* webpackChunkName: "components/payment-amount-table" */).then(c => wrapFunctional(c.default || c))
export const PaymentDiscountDetail = () => import('../../components/payment/PaymentDiscountDetail.vue' /* webpackChunkName: "components/payment-discount-detail" */).then(c => wrapFunctional(c.default || c))
export const PaymentNotice = () => import('../../components/payment/PaymentNotice.vue' /* webpackChunkName: "components/payment-notice" */).then(c => wrapFunctional(c.default || c))
export const PaymentTerms = () => import('../../components/payment/PaymentTerms.vue' /* webpackChunkName: "components/payment-terms" */).then(c => wrapFunctional(c.default || c))
export const PaymentTotalAmount = () => import('../../components/payment/PaymentTotalAmount.vue' /* webpackChunkName: "components/payment-total-amount" */).then(c => wrapFunctional(c.default || c))
export const PaymentProductShipping = () => import('../../components/payment/ProductShipping.vue' /* webpackChunkName: "components/payment-product-shipping" */).then(c => wrapFunctional(c.default || c))
export const PaymentProductTitle = () => import('../../components/payment/ProductTitle.vue' /* webpackChunkName: "components/payment-product-title" */).then(c => wrapFunctional(c.default || c))
export const PaymentRequestPaymentButton = () => import('../../components/payment/RequestPaymentButton.vue' /* webpackChunkName: "components/payment-request-payment-button" */).then(c => wrapFunctional(c.default || c))
export const PaymentScheduleAlarm = () => import('../../components/payment/ScheduleAlarm.vue' /* webpackChunkName: "components/payment-schedule-alarm" */).then(c => wrapFunctional(c.default || c))
export const PaymentSelectChild = () => import('../../components/payment/SelectChild.vue' /* webpackChunkName: "components/payment-select-child" */).then(c => wrapFunctional(c.default || c))
export const PaymentSubsPaymentMethod = () => import('../../components/payment/SubsPaymentMethod.vue' /* webpackChunkName: "components/payment-subs-payment-method" */).then(c => wrapFunctional(c.default || c))
export const PaymentUseMoney = () => import('../../components/payment/UseMoney.vue' /* webpackChunkName: "components/payment-use-money" */).then(c => wrapFunctional(c.default || c))
export const ProductShareBottomSheet = () => import('../../components/product/ProductShareBottomSheet.vue' /* webpackChunkName: "components/product-share-bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const ProductCuration = () => import('../../components/product-curation/ProductCuration.vue' /* webpackChunkName: "components/product-curation" */).then(c => wrapFunctional(c.default || c))
export const ProductItem = () => import('../../components/product-item/ProductItem.vue' /* webpackChunkName: "components/product-item" */).then(c => wrapFunctional(c.default || c))
export const ReviewAdmin = () => import('../../components/review/ReviewAdmin.vue' /* webpackChunkName: "components/review-admin" */).then(c => wrapFunctional(c.default || c))
export const ReviewContents = () => import('../../components/review/ReviewContents.vue' /* webpackChunkName: "components/review-contents" */).then(c => wrapFunctional(c.default || c))
export const ReviewHeader = () => import('../../components/review/ReviewHeader.vue' /* webpackChunkName: "components/review-header" */).then(c => wrapFunctional(c.default || c))
export const ReviewHelpful = () => import('../../components/review/ReviewHelpful.vue' /* webpackChunkName: "components/review-helpful" */).then(c => wrapFunctional(c.default || c))
export const ReviewItem = () => import('../../components/review/ReviewItem.vue' /* webpackChunkName: "components/review-item" */).then(c => wrapFunctional(c.default || c))
export const ReviewStars = () => import('../../components/review/ReviewStars.vue' /* webpackChunkName: "components/review-stars" */).then(c => wrapFunctional(c.default || c))
export const ReviewUser = () => import('../../components/review/ReviewUser.vue' /* webpackChunkName: "components/review-user" */).then(c => wrapFunctional(c.default || c))
export const ScheduleChecklist = () => import('../../components/schedule/Checklist.vue' /* webpackChunkName: "components/schedule-checklist" */).then(c => wrapFunctional(c.default || c))
export const ScheduleDetailHeader = () => import('../../components/schedule/DetailHeader.vue' /* webpackChunkName: "components/schedule-detail-header" */).then(c => wrapFunctional(c.default || c))
export const ScheduleDownloadButton = () => import('../../components/schedule/DownloadButton.vue' /* webpackChunkName: "components/schedule-download-button" */).then(c => wrapFunctional(c.default || c))
export const ScheduleProductLink = () => import('../../components/schedule/ProductLink.vue' /* webpackChunkName: "components/schedule-product-link" */).then(c => wrapFunctional(c.default || c))
export const ScheduleProductReplay = () => import('../../components/schedule/ProductReplay.vue' /* webpackChunkName: "components/schedule-product-replay" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCourseSection = () => import('../../components/schedule/ScheduleCourseSection.vue' /* webpackChunkName: "components/schedule-course-section" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCurriculum = () => import('../../components/schedule/ScheduleCurriculum.vue' /* webpackChunkName: "components/schedule-curriculum" */).then(c => wrapFunctional(c.default || c))
export const ScheduleDetailCheckListSection = () => import('../../components/schedule/ScheduleDetailCheckListSection.vue' /* webpackChunkName: "components/schedule-detail-check-list-section" */).then(c => wrapFunctional(c.default || c))
export const ScheduleJoin = () => import('../../components/schedule/ScheduleJoin.vue' /* webpackChunkName: "components/schedule-join" */).then(c => wrapFunctional(c.default || c))
export const ScheduleShare = () => import('../../components/schedule/ScheduleShare.vue' /* webpackChunkName: "components/schedule-share" */).then(c => wrapFunctional(c.default || c))
export const ScheduleTeacherSection = () => import('../../components/schedule/ScheduleTeacherSection.vue' /* webpackChunkName: "components/schedule-teacher-section" */).then(c => wrapFunctional(c.default || c))
export const ScheduleTitle = () => import('../../components/schedule/ScheduleTitle.vue' /* webpackChunkName: "components/schedule-title" */).then(c => wrapFunctional(c.default || c))
export const ScheduleVideoPlayer = () => import('../../components/schedule/VideoPlayer.vue' /* webpackChunkName: "components/schedule-video-player" */).then(c => wrapFunctional(c.default || c))
export const ScheduleZoomOpenConfirmModal = () => import('../../components/schedule/ZoomOpenConfirmModal.vue' /* webpackChunkName: "components/schedule-zoom-open-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const SearchAgeNGradeFilter = () => import('../../components/search/AgeNGradeFilter.vue' /* webpackChunkName: "components/search-age-n-grade-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchBottomFilterModalLayout = () => import('../../components/search/BottomFilterModalLayout.vue' /* webpackChunkName: "components/search-bottom-filter-modal-layout" */).then(c => wrapFunctional(c.default || c))
export const SearchChildAgeFilter = () => import('../../components/search/ChildAgeFilter.vue' /* webpackChunkName: "components/search-child-age-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptyHelpBox = () => import('../../components/search/EmptyHelpBox.vue' /* webpackChunkName: "components/search-empty-help-box" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptySearchFromNative = () => import('../../components/search/EmptySearchFromNative.vue' /* webpackChunkName: "components/search-empty-search-from-native" */).then(c => wrapFunctional(c.default || c))
export const SearchEmptySearchFromProduct = () => import('../../components/search/EmptySearchFromProduct.vue' /* webpackChunkName: "components/search-empty-search-from-product" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterButton = () => import('../../components/search/FilterButton.vue' /* webpackChunkName: "components/search-filter-button" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterOptionButton = () => import('../../components/search/FilterOptionButton.vue' /* webpackChunkName: "components/search-filter-option-button" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterOptions = () => import('../../components/search/FilterOptions.vue' /* webpackChunkName: "components/search-filter-options" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterSelector = () => import('../../components/search/FilterSelector.vue' /* webpackChunkName: "components/search-filter-selector" */).then(c => wrapFunctional(c.default || c))
export const SearchHorizontalSearchItem = () => import('../../components/search/HorizontalSearchItem.vue' /* webpackChunkName: "components/search-horizontal-search-item" */).then(c => wrapFunctional(c.default || c))
export const SearchInfiniteScrollSection = () => import('../../components/search/InfiniteScrollSection.vue' /* webpackChunkName: "components/search-infinite-scroll-section" */).then(c => wrapFunctional(c.default || c))
export const SearchInfiniteSearchListItem = () => import('../../components/search/InfiniteSearchListItem.vue' /* webpackChunkName: "components/search-infinite-search-list-item" */).then(c => wrapFunctional(c.default || c))
export const SearchKeywordSection = () => import('../../components/search/KeywordSection.vue' /* webpackChunkName: "components/search-keyword-section" */).then(c => wrapFunctional(c.default || c))
export const SearchNoFilterSearchList = () => import('../../components/search/NoFilterSearchList.vue' /* webpackChunkName: "components/search-no-filter-search-list" */).then(c => wrapFunctional(c.default || c))
export const SearchScheduleFilter = () => import('../../components/search/ScheduleFilter.vue' /* webpackChunkName: "components/search-schedule-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchScheduleNAgeFilter = () => import('../../components/search/ScheduleNAgeFilter.vue' /* webpackChunkName: "components/search-schedule-n-age-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchScheduleTimeSelect = () => import('../../components/search/ScheduleTimeSelect.vue' /* webpackChunkName: "components/search-schedule-time-select" */).then(c => wrapFunctional(c.default || c))
export const SearchHeader = () => import('../../components/search/SearchHeader.vue' /* webpackChunkName: "components/search-header" */).then(c => wrapFunctional(c.default || c))
export const SearchListSection = () => import('../../components/search/SearchListSection.vue' /* webpackChunkName: "components/search-list-section" */).then(c => wrapFunctional(c.default || c))
export const SearchSelectedFilterOption = () => import('../../components/search/SelectedFilterOption.vue' /* webpackChunkName: "components/search-selected-filter-option" */).then(c => wrapFunctional(c.default || c))
export const SkeletonHorizontalSearchSkeleton = () => import('../../components/skeleton/HorizontalSearchSkeleton.vue' /* webpackChunkName: "components/skeleton-horizontal-search-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonPickedReviewSkeleton = () => import('../../components/skeleton/PickedReviewSkeleton.vue' /* webpackChunkName: "components/skeleton-picked-review-skeleton" */).then(c => wrapFunctional(c.default || c))
export const SkeletonVerticalProductSkeleton = () => import('../../components/skeleton/VerticalProductSkeleton.vue' /* webpackChunkName: "components/skeleton-vertical-product-skeleton" */).then(c => wrapFunctional(c.default || c))
export const TeacherClassSection = () => import('../../components/teacher/TeacherClassSection.vue' /* webpackChunkName: "components/teacher-class-section" */).then(c => wrapFunctional(c.default || c))
export const TeacherSearchFilter = () => import('../../components/teacher/TeacherSearchFilter.vue' /* webpackChunkName: "components/teacher-search-filter" */).then(c => wrapFunctional(c.default || c))
export const TeacherSearchItem = () => import('../../components/teacher/TeacherSearchItem.vue' /* webpackChunkName: "components/teacher-search-item" */).then(c => wrapFunctional(c.default || c))
export const TeacherSearchSection = () => import('../../components/teacher/TeacherSearchSection.vue' /* webpackChunkName: "components/teacher-search-section" */).then(c => wrapFunctional(c.default || c))
export const TeacherShareBottomSheet = () => import('../../components/teacher/TeacherShareBottomSheet.vue' /* webpackChunkName: "components/teacher-share-bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const TeacherSummarySection = () => import('../../components/teacher/TeacherSummarySection.vue' /* webpackChunkName: "components/teacher-summary-section" */).then(c => wrapFunctional(c.default || c))
export const TeacherTabBar = () => import('../../components/teacher/TeacherTabBar.vue' /* webpackChunkName: "components/teacher-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerMobileVideoPlayer = () => import('../../components/video-player/MobileVideoPlayer.vue' /* webpackChunkName: "components/video-player-mobile-video-player" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/video-player/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const CalendarComponentsDate = () => import('../../components/Calendar/components/Date.vue' /* webpackChunkName: "components/calendar-components-date" */).then(c => wrapFunctional(c.default || c))
export const CalendarComponentsIndicator = () => import('../../components/Calendar/components/Indicator.vue' /* webpackChunkName: "components/calendar-components-indicator" */).then(c => wrapFunctional(c.default || c))
export const CalendarComponentsMonth = () => import('../../components/Calendar/components/Month.vue' /* webpackChunkName: "components/calendar-components-month" */).then(c => wrapFunctional(c.default || c))
export const CalendarComponentsWeek = () => import('../../components/Calendar/components/Week.vue' /* webpackChunkName: "components/calendar-components-week" */).then(c => wrapFunctional(c.default || c))
export const CalendarComponentsWeekHeader = () => import('../../components/Calendar/components/WeekHeader.vue' /* webpackChunkName: "components/calendar-components-week-header" */).then(c => wrapFunctional(c.default || c))
export const BlogPriceRangeEmptyPriceRangeBestItem = () => import('../../components/blog/price-range/EmptyPriceRangeBestItem.vue' /* webpackChunkName: "components/blog-price-range-empty-price-range-best-item" */).then(c => wrapFunctional(c.default || c))
export const BlogPriceRangeBestItem = () => import('../../components/blog/price-range/PriceRangeBestItem.vue' /* webpackChunkName: "components/blog-price-range-best-item" */).then(c => wrapFunctional(c.default || c))
export const BlogPriceRangeBestSection = () => import('../../components/blog/price-range/PriceRangeBestSection.vue' /* webpackChunkName: "components/blog-price-range-best-section" */).then(c => wrapFunctional(c.default || c))
export const BlogPriceRangeBestTabBar = () => import('../../components/blog/price-range/PriceRangeBestTabBar.vue' /* webpackChunkName: "components/blog-price-range-best-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelLastMessage = () => import('../../components/chat/channelList/ChannelLastMessage.vue' /* webpackChunkName: "components/chat-channel-last-message" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelList = () => import('../../components/chat/channelList/ChannelList.vue' /* webpackChunkName: "components/chat-channel-list" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelWrapper = () => import('../../components/chat/channelList/ChannelWrapper.vue' /* webpackChunkName: "components/chat-channel-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelListOneMemberChannel = () => import('../../components/chat/channelList/OneMemberChannel.vue' /* webpackChunkName: "components/chat-channel-list-one-member-channel" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelListSendTime = () => import('../../components/chat/channelList/SendTime.vue' /* webpackChunkName: "components/chat-channel-list-send-time" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelListTeacherNickname = () => import('../../components/chat/channelList/TeacherNickname.vue' /* webpackChunkName: "components/chat-channel-list-teacher-nickname" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelListTwoMembersChannel = () => import('../../components/chat/channelList/TwoMembersChannel.vue' /* webpackChunkName: "components/chat-channel-list-two-members-channel" */).then(c => wrapFunctional(c.default || c))
export const ChatChannelListUnreadCountBadge = () => import('../../components/chat/channelList/UnreadCountBadge.vue' /* webpackChunkName: "components/chat-channel-list-unread-count-badge" */).then(c => wrapFunctional(c.default || c))
export const ChatNonLogin = () => import('../../components/chat/chatStart/ChatNonLogin.vue' /* webpackChunkName: "components/chat-non-login" */).then(c => wrapFunctional(c.default || c))
export const ChatStart = () => import('../../components/chat/chatStart/ChatStart.vue' /* webpackChunkName: "components/chat-start" */).then(c => wrapFunctional(c.default || c))
export const ChatStartWrapper = () => import('../../components/chat/chatStart/ChatStartWrapper.vue' /* webpackChunkName: "components/chat-start-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ChatChatStartDisableQuestionButton = () => import('../../components/chat/chatStart/DisableQuestionButton.vue' /* webpackChunkName: "components/chat-chat-start-disable-question-button" */).then(c => wrapFunctional(c.default || c))
export const ChatChatStartSendSuccessAlert = () => import('../../components/chat/chatStart/SendSuccessAlert.vue' /* webpackChunkName: "components/chat-chat-start-send-success-alert" */).then(c => wrapFunctional(c.default || c))
export const ChatEmojiButton = () => import('../../components/chat/emoji/EmojiButton.vue' /* webpackChunkName: "components/chat-emoji-button" */).then(c => wrapFunctional(c.default || c))
export const ChatEmojiMessage = () => import('../../components/chat/emoji/EmojiMessage.vue' /* webpackChunkName: "components/chat-emoji-message" */).then(c => wrapFunctional(c.default || c))
export const ChatEmojiModal = () => import('../../components/chat/emoji/EmojiModal.vue' /* webpackChunkName: "components/chat-emoji-modal" */).then(c => wrapFunctional(c.default || c))
export const ChatEmojiModalItem = () => import('../../components/chat/emoji/EmojiModalItem.vue' /* webpackChunkName: "components/chat-emoji-modal-item" */).then(c => wrapFunctional(c.default || c))
export const Common2024GgugeDayNudge = () => import('../../components/common/2024GgugeDay/nudge.vue' /* webpackChunkName: "components/common2024-gguge-day-nudge" */).then(c => wrapFunctional(c.default || c))
export const CommonBadge = () => import('../../components/common/badge/Badge.vue' /* webpackChunkName: "components/common-badge" */).then(c => wrapFunctional(c.default || c))
export const CommonBadgeProductTag = () => import('../../components/common/badge/ProductTag.vue' /* webpackChunkName: "components/common-badge-product-tag" */).then(c => wrapFunctional(c.default || c))
export const CommonBadgeRibbonLabel = () => import('../../components/common/badge/RibbonLabel.vue' /* webpackChunkName: "components/common-badge-ribbon-label" */).then(c => wrapFunctional(c.default || c))
export const CommonBadgeScheduleStatusTag = () => import('../../components/common/badge/ScheduleStatusTag.vue' /* webpackChunkName: "components/common-badge-schedule-status-tag" */).then(c => wrapFunctional(c.default || c))
export const CommonBottomSheet = () => import('../../components/common/bottomSheet/BottomSheet.vue' /* webpackChunkName: "components/common-bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const CommonBottomSheetContents = () => import('../../components/common/bottomSheet/BottomSheetContents.vue' /* webpackChunkName: "components/common-bottom-sheet-contents" */).then(c => wrapFunctional(c.default || c))
export const CommonBtnArrowBtn = () => import('../../components/common/btn/ArrowBtn.vue' /* webpackChunkName: "components/common-btn-arrow-btn" */).then(c => wrapFunctional(c.default || c))
export const CommonBtnBoxButton = () => import('../../components/common/btn/BoxButton.vue' /* webpackChunkName: "components/common-btn-box-button" */).then(c => wrapFunctional(c.default || c))
export const CommonBtnFavoriteButton = () => import('../../components/common/btn/FavoriteButton.vue' /* webpackChunkName: "components/common-btn-favorite-button" */).then(c => wrapFunctional(c.default || c))
export const CommonBtnFloatButton = () => import('../../components/common/btn/FloatButton.vue' /* webpackChunkName: "components/common-btn-float-button" */).then(c => wrapFunctional(c.default || c))
export const CommonBtnLinkButton = () => import('../../components/common/btn/LinkButton.vue' /* webpackChunkName: "components/common-btn-link-button" */).then(c => wrapFunctional(c.default || c))
export const CommonBtnMenuButton = () => import('../../components/common/btn/MenuButton.vue' /* webpackChunkName: "components/common-btn-menu-button" */).then(c => wrapFunctional(c.default || c))
export const CommonBtnNudgeBtn = () => import('../../components/common/btn/NudgeBtn.vue' /* webpackChunkName: "components/common-btn-nudge-btn" */).then(c => wrapFunctional(c.default || c))
export const CommonFormCheckBox = () => import('../../components/common/form/CheckBox.vue' /* webpackChunkName: "components/common-form-check-box" */).then(c => wrapFunctional(c.default || c))
export const CommonFormPhoneTextField = () => import('../../components/common/form/PhoneTextField.vue' /* webpackChunkName: "components/common-form-phone-text-field" */).then(c => wrapFunctional(c.default || c))
export const CommonFormRadioGroup = () => import('../../components/common/form/RadioGroup.vue' /* webpackChunkName: "components/common-form-radio-group" */).then(c => wrapFunctional(c.default || c))
export const CommonFormSelect = () => import('../../components/common/form/Select.vue' /* webpackChunkName: "components/common-form-select" */).then(c => wrapFunctional(c.default || c))
export const CommonFormSlideCheckBox = () => import('../../components/common/form/SlideCheckBox.vue' /* webpackChunkName: "components/common-form-slide-check-box" */).then(c => wrapFunctional(c.default || c))
export const CommonFormTextArea = () => import('../../components/common/form/TextArea.vue' /* webpackChunkName: "components/common-form-text-area" */).then(c => wrapFunctional(c.default || c))
export const CommonFormTextField = () => import('../../components/common/form/TextField.vue' /* webpackChunkName: "components/common-form-text-field" */).then(c => wrapFunctional(c.default || c))
export const CommonFormVerticalRadioGroup = () => import('../../components/common/form/VerticalRadioGroup.vue' /* webpackChunkName: "components/common-form-vertical-radio-group" */).then(c => wrapFunctional(c.default || c))
export const CommonIcon = () => import('../../components/common/icon/Icon.vue' /* webpackChunkName: "components/common-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonIconSvgCircle = () => import('../../components/common/icon/SvgCircle.vue' /* webpackChunkName: "components/common-icon-svg-circle" */).then(c => wrapFunctional(c.default || c))
export const CommonIconSvgPath = () => import('../../components/common/icon/SvgPath.vue' /* webpackChunkName: "components/common-icon-svg-path" */).then(c => wrapFunctional(c.default || c))
export const CommonImgRoundAvatar = () => import('../../components/common/img/RoundAvatar.vue' /* webpackChunkName: "components/common-img-round-avatar" */).then(c => wrapFunctional(c.default || c))
export const CommonImgThumbnail = () => import('../../components/common/img/Thumbnail.vue' /* webpackChunkName: "components/common-img-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const CommonModalLoginModal = () => import('../../components/common/modal/LoginModal.vue' /* webpackChunkName: "components/common-modal-login-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonNavAuthUserNavMenu = () => import('../../components/common/nav/AuthUserNavMenu.vue' /* webpackChunkName: "components/common-nav-auth-user-nav-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonNavLandingNavMenu = () => import('../../components/common/nav/LandingNavMenu.vue' /* webpackChunkName: "components/common-nav-landing-nav-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonNavMenuList = () => import('../../components/common/nav/MenuList.vue' /* webpackChunkName: "components/common-nav-menu-list" */).then(c => wrapFunctional(c.default || c))
export const CommonNavLogo = () => import('../../components/common/nav/NavLogo.vue' /* webpackChunkName: "components/common-nav-logo" */).then(c => wrapFunctional(c.default || c))
export const CommonNavNavigationBar = () => import('../../components/common/nav/NavigationBar.vue' /* webpackChunkName: "components/common-nav-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const CommonNavSearchBar = () => import('../../components/common/nav/SearchBar.vue' /* webpackChunkName: "components/common-nav-search-bar" */).then(c => wrapFunctional(c.default || c))
export const CommonProductAlreadyParticipated = () => import('../../components/common/product/AlreadyParticipated.vue' /* webpackChunkName: "components/common-product-already-participated" */).then(c => wrapFunctional(c.default || c))
export const CommonProductCurriculumItem = () => import('../../components/common/product/CurriculumItem.vue' /* webpackChunkName: "components/common-product-curriculum-item" */).then(c => wrapFunctional(c.default || c))
export const CommonProductHorizontalFavoriteButton = () => import('../../components/common/product/HorizontalFavoriteButton.vue' /* webpackChunkName: "components/common-product-horizontal-favorite-button" */).then(c => wrapFunctional(c.default || c))
export const CommonProductHorizontalThumbItem = () => import('../../components/common/product/HorizontalThumbItem.vue' /* webpackChunkName: "components/common-product-horizontal-thumb-item" */).then(c => wrapFunctional(c.default || c))
export const CommonProductThumbnail = () => import('../../components/common/product/Thumbnail.vue' /* webpackChunkName: "components/common-product-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const CommonRankingFilterIndicatorTabBar = () => import('../../components/common/ranking/FilterIndicatorTabBar.vue' /* webpackChunkName: "components/common-ranking-filter-indicator-tab-bar" */).then(c => wrapFunctional(c.default || c))
export const CommonRankingRank = () => import('../../components/common/ranking/Rank.vue' /* webpackChunkName: "components/common-ranking-rank" */).then(c => wrapFunctional(c.default || c))
export const CommonRankingClassProfile = () => import('../../components/common/ranking/RankingClassProfile.vue' /* webpackChunkName: "components/common-ranking-class-profile" */).then(c => wrapFunctional(c.default || c))
export const CommonRankingFavorite = () => import('../../components/common/ranking/RankingFavorite.vue' /* webpackChunkName: "components/common-ranking-favorite" */).then(c => wrapFunctional(c.default || c))
export const CommonRankingItem = () => import('../../components/common/ranking/RankingItem.vue' /* webpackChunkName: "components/common-ranking-item" */).then(c => wrapFunctional(c.default || c))
export const CommonRankingThumbnail = () => import('../../components/common/ranking/RankingThumbnail.vue' /* webpackChunkName: "components/common-ranking-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const CommonRankingTitle = () => import('../../components/common/ranking/RankingTitle.vue' /* webpackChunkName: "components/common-ranking-title" */).then(c => wrapFunctional(c.default || c))
export const CommonSwiper = () => import('../../components/common/swiper/CommonSwiper.vue' /* webpackChunkName: "components/common-swiper" */).then(c => wrapFunctional(c.default || c))
export const CommonSwiperCssSwiper = () => import('../../components/common/swiper/CssSwiper.vue' /* webpackChunkName: "components/common-swiper-css-swiper" */).then(c => wrapFunctional(c.default || c))
export const CommonSwiperCurationSwiper = () => import('../../components/common/swiper/CurationSwiper.vue' /* webpackChunkName: "components/common-swiper-curation-swiper" */).then(c => wrapFunctional(c.default || c))
export const CommonTag = () => import('../../components/common/tag/Tag.vue' /* webpackChunkName: "components/common-tag" */).then(c => wrapFunctional(c.default || c))
export const CommonTemplateRowTable = () => import('../../components/common/template/RowTable.vue' /* webpackChunkName: "components/common-template-row-table" */).then(c => wrapFunctional(c.default || c))
export const CouponUsableProductGrid = () => import('../../components/coupon/usableProduct/UsableProductGrid.vue' /* webpackChunkName: "components/coupon-usable-product-grid" */).then(c => wrapFunctional(c.default || c))
export const CouponUsableProductHeader = () => import('../../components/coupon/usableProduct/UsableProductHeader.vue' /* webpackChunkName: "components/coupon-usable-product-header" */).then(c => wrapFunctional(c.default || c))
export const CourseCurriculum = () => import('../../components/course/course-item/CourseCurriculum.vue' /* webpackChunkName: "components/course-curriculum" */).then(c => wrapFunctional(c.default || c))
export const CourseFavoriteButton = () => import('../../components/course/course-item/CourseFavoriteButton.vue' /* webpackChunkName: "components/course-favorite-button" */).then(c => wrapFunctional(c.default || c))
export const CourseItem = () => import('../../components/course/course-item/CourseItem.vue' /* webpackChunkName: "components/course-item" */).then(c => wrapFunctional(c.default || c))
export const CourseTag = () => import('../../components/course/course-item/CourseTag.vue' /* webpackChunkName: "components/course-tag" */).then(c => wrapFunctional(c.default || c))
export const CourseTitle = () => import('../../components/course/course-item/CourseTitle.vue' /* webpackChunkName: "components/course-title" */).then(c => wrapFunctional(c.default || c))
export const CourseCourseItemDefaultAvatar = () => import('../../components/course/course-item/DefaultAvatar.vue' /* webpackChunkName: "components/course-course-item-default-avatar" */).then(c => wrapFunctional(c.default || c))
export const CourseCourseItemTagList = () => import('../../components/course/course-item/TagList.vue' /* webpackChunkName: "components/course-course-item-tag-list" */).then(c => wrapFunctional(c.default || c))
export const CourseCourseItemTeacherImageList = () => import('../../components/course/course-item/TeacherImageList.vue' /* webpackChunkName: "components/course-course-item-teacher-image-list" */).then(c => wrapFunctional(c.default || c))
export const CourseCourseItemThumbnailItem = () => import('../../components/course/course-item/ThumbnailItem.vue' /* webpackChunkName: "components/course-course-item-thumbnail-item" */).then(c => wrapFunctional(c.default || c))
export const CourseDescription = () => import('../../components/course/description/CourseDescription.vue' /* webpackChunkName: "components/course-description" */).then(c => wrapFunctional(c.default || c))
export const CourseDifferentWhatIsDifferent = () => import('../../components/course/different/WhatIsDifferent.vue' /* webpackChunkName: "components/course-different-what-is-different" */).then(c => wrapFunctional(c.default || c))
export const CourseNavigator = () => import('../../components/course/navigator/CourseNavigator.vue' /* webpackChunkName: "components/course-navigator" */).then(c => wrapFunctional(c.default || c))
export const CourseClassList = () => import('../../components/course/related-class/CourseClassList.vue' /* webpackChunkName: "components/course-class-list" */).then(c => wrapFunctional(c.default || c))
export const CourseProgress = () => import('../../components/course/related-class/CourseProgress.vue' /* webpackChunkName: "components/course-progress" */).then(c => wrapFunctional(c.default || c))
export const CourseRelatedClass = () => import('../../components/course/related-class/CourseRelatedClass.vue' /* webpackChunkName: "components/course-related-class" */).then(c => wrapFunctional(c.default || c))
export const CourseInfo = () => import('../../components/course/summary/CourseInfo.vue' /* webpackChunkName: "components/course-info" */).then(c => wrapFunctional(c.default || c))
export const CourseSummary = () => import('../../components/course/summary/CourseSummary.vue' /* webpackChunkName: "components/course-summary" */).then(c => wrapFunctional(c.default || c))
export const CourseSummaryTitle = () => import('../../components/course/summary/CourseSummaryTitle.vue' /* webpackChunkName: "components/course-summary-title" */).then(c => wrapFunctional(c.default || c))
export const CourseSummaryDetailCourseFavorite = () => import('../../components/course/summary/DetailCourseFavorite.vue' /* webpackChunkName: "components/course-summary-detail-course-favorite" */).then(c => wrapFunctional(c.default || c))
export const CourseSummaryTagList = () => import('../../components/course/summary/TagList.vue' /* webpackChunkName: "components/course-summary-tag-list" */).then(c => wrapFunctional(c.default || c))
export const CourseCuration = () => import('../../components/course/swiper/CourseCuration.vue' /* webpackChunkName: "components/course-curation" */).then(c => wrapFunctional(c.default || c))
export const CourseFavorite = () => import('../../components/course/thumbnail/CourseFavorite.vue' /* webpackChunkName: "components/course-favorite" */).then(c => wrapFunctional(c.default || c))
export const CourseThumbnail = () => import('../../components/course/thumbnail/CourseThumbnail.vue' /* webpackChunkName: "components/course-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const LayoutCategoryItem = () => import('../../components/layout/category-list/CategoryItem.vue' /* webpackChunkName: "components/layout-category-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutCategoryListModal = () => import('../../components/layout/category-list/CategoryListModal.vue' /* webpackChunkName: "components/layout-category-list-modal" */).then(c => wrapFunctional(c.default || c))
export const LayoutCategoryListDepthItem = () => import('../../components/layout/category-list/DepthItem.vue' /* webpackChunkName: "components/layout-category-list-depth-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutCategoryListDepthList = () => import('../../components/layout/category-list/DepthList.vue' /* webpackChunkName: "components/layout-category-list-depth-list" */).then(c => wrapFunctional(c.default || c))
export const LayoutDrawerAuthProfile = () => import('../../components/layout/drawer/AuthProfile.vue' /* webpackChunkName: "components/layout-drawer-auth-profile" */).then(c => wrapFunctional(c.default || c))
export const LayoutDrawerMenu = () => import('../../components/layout/drawer/DrawerMenu.vue' /* webpackChunkName: "components/layout-drawer-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutDrawerReviewTooltip = () => import('../../components/layout/drawer/DrawerReviewTooltip.vue' /* webpackChunkName: "components/layout-drawer-review-tooltip" */).then(c => wrapFunctional(c.default || c))
export const LayoutDrawerExpansionSection = () => import('../../components/layout/drawer/ExpansionSection.vue' /* webpackChunkName: "components/layout-drawer-expansion-section" */).then(c => wrapFunctional(c.default || c))
export const LayoutDrawerFavoriteClassList = () => import('../../components/layout/drawer/FavoriteClassList.vue' /* webpackChunkName: "components/layout-drawer-favorite-class-list" */).then(c => wrapFunctional(c.default || c))
export const LayoutDrawerFavoriteCourseList = () => import('../../components/layout/drawer/FavoriteCourseList.vue' /* webpackChunkName: "components/layout-drawer-favorite-course-list" */).then(c => wrapFunctional(c.default || c))
export const LayoutDrawerNotice = () => import('../../components/layout/drawer/Notice.vue' /* webpackChunkName: "components/layout-drawer-notice" */).then(c => wrapFunctional(c.default || c))
export const LayoutDrawerUnAuthProfile = () => import('../../components/layout/drawer/UnAuthProfile.vue' /* webpackChunkName: "components/layout-drawer-un-auth-profile" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterAppFooter = () => import('../../components/layout/footer/AppFooter.vue' /* webpackChunkName: "components/layout-footer-app-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterCustomerService = () => import('../../components/layout/footer/CustomerService.vue' /* webpackChunkName: "components/layout-footer-customer-service" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterDownloadApp = () => import('../../components/layout/footer/DownloadApp.vue' /* webpackChunkName: "components/layout-footer-download-app" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterPartner = () => import('../../components/layout/footer/FooterPartner.vue' /* webpackChunkName: "components/layout-footer-partner" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterService = () => import('../../components/layout/footer/FooterService.vue' /* webpackChunkName: "components/layout-footer-service" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterSocialLink = () => import('../../components/layout/footer/SocialLink.vue' /* webpackChunkName: "components/layout-footer-social-link" */).then(c => wrapFunctional(c.default || c))
export const MyAlarmList = () => import('../../components/my/alarm/AlarmList.vue' /* webpackChunkName: "components/my-alarm-list" */).then(c => wrapFunctional(c.default || c))
export const MyAlarmListItem = () => import('../../components/my/alarm/AlarmListItem.vue' /* webpackChunkName: "components/my-alarm-list-item" */).then(c => wrapFunctional(c.default || c))
export const MyCancelHeader = () => import('../../components/my/cancel/CancelHeader.vue' /* webpackChunkName: "components/my-cancel-header" */).then(c => wrapFunctional(c.default || c))
export const MyCancelNotice = () => import('../../components/my/cancel/CancelNotice.vue' /* webpackChunkName: "components/my-cancel-notice" */).then(c => wrapFunctional(c.default || c))
export const MyCancelPolicyTable = () => import('../../components/my/cancel/CancelPolicyTable.vue' /* webpackChunkName: "components/my-cancel-policy-table" */).then(c => wrapFunctional(c.default || c))
export const MyCancelReasonSelect = () => import('../../components/my/cancel/CancelReasonSelect.vue' /* webpackChunkName: "components/my-cancel-reason-select" */).then(c => wrapFunctional(c.default || c))
export const MyCancelRequestButton = () => import('../../components/my/cancel/CancelRequestButton.vue' /* webpackChunkName: "components/my-cancel-request-button" */).then(c => wrapFunctional(c.default || c))
export const MyCancelClassDetail = () => import('../../components/my/cancel/ClassDetail.vue' /* webpackChunkName: "components/my-cancel-class-detail" */).then(c => wrapFunctional(c.default || c))
export const MyCancelNoCancelSchedule = () => import('../../components/my/cancel/NoCancelSchedule.vue' /* webpackChunkName: "components/my-cancel-no-cancel-schedule" */).then(c => wrapFunctional(c.default || c))
export const MyCancelOrderDetail = () => import('../../components/my/cancel/OrderDetail.vue' /* webpackChunkName: "components/my-cancel-order-detail" */).then(c => wrapFunctional(c.default || c))
export const MyCancelPaymentDetail = () => import('../../components/my/cancel/PaymentDetail.vue' /* webpackChunkName: "components/my-cancel-payment-detail" */).then(c => wrapFunctional(c.default || c))
export const MyCancelProductCard = () => import('../../components/my/cancel/ProductCard.vue' /* webpackChunkName: "components/my-cancel-product-card" */).then(c => wrapFunctional(c.default || c))
export const MyCancelRefundDetail = () => import('../../components/my/cancel/RefundDetail.vue' /* webpackChunkName: "components/my-cancel-refund-detail" */).then(c => wrapFunctional(c.default || c))
export const MyCouponHeader = () => import('../../components/my/coupon/CouponHeader.vue' /* webpackChunkName: "components/my-coupon-header" */).then(c => wrapFunctional(c.default || c))
export const MyCouponItem = () => import('../../components/my/coupon/CouponItem.vue' /* webpackChunkName: "components/my-coupon-item" */).then(c => wrapFunctional(c.default || c))
export const MyCouponList = () => import('../../components/my/coupon/CouponList.vue' /* webpackChunkName: "components/my-coupon-list" */).then(c => wrapFunctional(c.default || c))
export const MyCouponNotice = () => import('../../components/my/coupon/CouponNotice.vue' /* webpackChunkName: "components/my-coupon-notice" */).then(c => wrapFunctional(c.default || c))
export const MyCouponPolicy = () => import('../../components/my/coupon/CouponPolicy.vue' /* webpackChunkName: "components/my-coupon-policy" */).then(c => wrapFunctional(c.default || c))
export const MyCouponTabList = () => import('../../components/my/coupon/CouponTabList.vue' /* webpackChunkName: "components/my-coupon-tab-list" */).then(c => wrapFunctional(c.default || c))
export const MyCouponWrapper = () => import('../../components/my/coupon/CouponWrapper.vue' /* webpackChunkName: "components/my-coupon-wrapper" */).then(c => wrapFunctional(c.default || c))
export const MyCouponAdd = () => import('../../components/my/coupon/MyCouponAdd.vue' /* webpackChunkName: "components/my-coupon-add" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyChargeAgreements = () => import('../../components/my/money/ChargeAgreements.vue' /* webpackChunkName: "components/my-money-charge-agreements" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyChargeMoneyInputSection = () => import('../../components/my/money/ChargeMoneyInputSection.vue' /* webpackChunkName: "components/my-money-charge-money-input-section" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyChargeMoneyInputSectionTooltipContent = () => import('../../components/my/money/ChargeMoneyInputSectionTooltipContent.vue' /* webpackChunkName: "components/my-money-charge-money-input-section-tooltip-content" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyChargeMoneySummary = () => import('../../components/my/money/ChargeMoneySummary.vue' /* webpackChunkName: "components/my-money-charge-money-summary" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyChargePaymentRequestButton = () => import('../../components/my/money/ChargePaymentRequestButton.vue' /* webpackChunkName: "components/my-money-charge-payment-request-button" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyGgugeMoneyHeader = () => import('../../components/my/money/GgugeMoneyHeader.vue' /* webpackChunkName: "components/my-money-gguge-money-header" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyCodeModal = () => import('../../components/my/money/MoneyCodeModal.vue' /* webpackChunkName: "components/my-money-code-modal" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyHistory = () => import('../../components/my/money/MoneyHistory.vue' /* webpackChunkName: "components/my-money-history" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyHistoryItem = () => import('../../components/my/money/MoneyHistoryItem.vue' /* webpackChunkName: "components/my-money-history-item" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyHistoryList = () => import('../../components/my/money/MoneyHistoryList.vue' /* webpackChunkName: "components/my-money-history-list" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyTextField = () => import('../../components/my/money/MoneyTextField.vue' /* webpackChunkName: "components/my-money-text-field" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyStatus = () => import('../../components/my/money/MyMoneyStatus.vue' /* webpackChunkName: "components/my-money-status" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyStatusTooltipContent = () => import('../../components/my/money/MyMoneyStatusTooltipContent.vue' /* webpackChunkName: "components/my-money-status-tooltip-content" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyTotal = () => import('../../components/my/money/MyMoneyTotal.vue' /* webpackChunkName: "components/my-money-total" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyTotalStatus = () => import('../../components/my/money/MyMoneyTotalStatus.vue' /* webpackChunkName: "components/my-money-total-status" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyNoHistory = () => import('../../components/my/money/NoHistory.vue' /* webpackChunkName: "components/my-money-no-history" */).then(c => wrapFunctional(c.default || c))
export const MyMoneyPaymentMethod = () => import('../../components/my/money/PaymentMethod.vue' /* webpackChunkName: "components/my-money-payment-method" */).then(c => wrapFunctional(c.default || c))
export const MyOrderCancelPenaltyExpansion = () => import('../../components/my/order/CancelPenaltyExpansion.vue' /* webpackChunkName: "components/my-order-cancel-penalty-expansion" */).then(c => wrapFunctional(c.default || c))
export const MyOrderDisplayTotalAmount = () => import('../../components/my/order/DisplayTotalAmount.vue' /* webpackChunkName: "components/my-order-display-total-amount" */).then(c => wrapFunctional(c.default || c))
export const MyOrderExpansion = () => import('../../components/my/order/Expansion.vue' /* webpackChunkName: "components/my-order-expansion" */).then(c => wrapFunctional(c.default || c))
export const MyEmptyList = () => import('../../components/my/order/MyEmptyList.vue' /* webpackChunkName: "components/my-empty-list" */).then(c => wrapFunctional(c.default || c))
export const MyPaymentItem = () => import('../../components/my/order/MyPaymentItem.vue' /* webpackChunkName: "components/my-payment-item" */).then(c => wrapFunctional(c.default || c))
export const MyOrderPaymentInfo = () => import('../../components/my/order/PaymentInfo.vue' /* webpackChunkName: "components/my-order-payment-info" */).then(c => wrapFunctional(c.default || c))
export const MyOrderRefundInfo = () => import('../../components/my/order/RefundInfo.vue' /* webpackChunkName: "components/my-order-refund-info" */).then(c => wrapFunctional(c.default || c))
export const MyOrderScheduleList = () => import('../../components/my/order/ScheduleList.vue' /* webpackChunkName: "components/my-order-schedule-list" */).then(c => wrapFunctional(c.default || c))
export const MyReviewCreateReviewResponseModal = () => import('../../components/my/review/CreateReviewResponseModal.vue' /* webpackChunkName: "components/my-review-create-review-response-modal" */).then(c => wrapFunctional(c.default || c))
export const MyReviewErrorModal = () => import('../../components/my/review/ReviewErrorModal.vue' /* webpackChunkName: "components/my-review-error-modal" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionCancelTypeSelect = () => import('../../components/my/subscription/CancelTypeSelect.vue' /* webpackChunkName: "components/my-subscription-cancel-type-select" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionConfirmNudge = () => import('../../components/my/subscription/ConfirmNudge.vue' /* webpackChunkName: "components/my-subscription-confirm-nudge" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionFilter = () => import('../../components/my/subscription/MySubscriptionFilter.vue' /* webpackChunkName: "components/my-subscription-filter" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionNotice = () => import('../../components/my/subscription/MySubscriptionNotice.vue' /* webpackChunkName: "components/my-subscription-notice" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionRestoreSubsModal = () => import('../../components/my/subscription/RestoreSubsModal.vue' /* webpackChunkName: "components/my-subscription-restore-subs-modal" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionCancelReason = () => import('../../components/my/subscription/SubscriptionCancelReason.vue' /* webpackChunkName: "components/my-subscription-cancel-reason" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionCancelRequestButton = () => import('../../components/my/subscription/SubscriptionCancelRequestButton.vue' /* webpackChunkName: "components/my-subscription-cancel-request-button" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionProduct = () => import('../../components/my/subscription/SubscriptionProduct.vue' /* webpackChunkName: "components/my-subscription-product" */).then(c => wrapFunctional(c.default || c))
export const NewHomeHeader = () => import('../../components/new-home/layout/HomeHeader.vue' /* webpackChunkName: "components/new-home-header" */).then(c => wrapFunctional(c.default || c))
export const NewHomeLayoutKeywordItem = () => import('../../components/new-home/layout/KeywordItem.vue' /* webpackChunkName: "components/new-home-layout-keyword-item" */).then(c => wrapFunctional(c.default || c))
export const NewHomeLayoutKeywordList = () => import('../../components/new-home/layout/KeywordList.vue' /* webpackChunkName: "components/new-home-layout-keyword-list" */).then(c => wrapFunctional(c.default || c))
export const NewHomeLayoutPageSwitch = () => import('../../components/new-home/layout/PageSwitch.vue' /* webpackChunkName: "components/new-home-layout-page-switch" */).then(c => wrapFunctional(c.default || c))
export const NewHomeLayoutSignupNudge = () => import('../../components/new-home/layout/SignupNudge.vue' /* webpackChunkName: "components/new-home-layout-signup-nudge" */).then(c => wrapFunctional(c.default || c))
export const NewHomeLayoutSocialProof = () => import('../../components/new-home/layout/SocialProof.vue' /* webpackChunkName: "components/new-home-layout-social-proof" */).then(c => wrapFunctional(c.default || c))
export const NewHomeLayoutSocialProofImage = () => import('../../components/new-home/layout/SocialProofImage.vue' /* webpackChunkName: "components/new-home-layout-social-proof-image" */).then(c => wrapFunctional(c.default || c))
export const NewHomeLayoutStaccatoChar = () => import('../../components/new-home/layout/StaccatoChar.vue' /* webpackChunkName: "components/new-home-layout-staccato-char" */).then(c => wrapFunctional(c.default || c))
export const NewHomeLayoutUnAuthBottom = () => import('../../components/new-home/layout/UnAuthBottom.vue' /* webpackChunkName: "components/new-home-layout-un-auth-bottom" */).then(c => wrapFunctional(c.default || c))
export const NewProductActionsActionSection = () => import('../../components/new-product/actions/ActionSection.vue' /* webpackChunkName: "components/new-product-actions-action-section" */).then(c => wrapFunctional(c.default || c))
export const NewProductActionsAlarmAction = () => import('../../components/new-product/actions/AlarmAction.vue' /* webpackChunkName: "components/new-product-actions-alarm-action" */).then(c => wrapFunctional(c.default || c))
export const NewProductActionsAlarmConfirmModal = () => import('../../components/new-product/actions/AlarmConfirmModal.vue' /* webpackChunkName: "components/new-product-actions-alarm-confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const NewProductActionsFavoriteAction = () => import('../../components/new-product/actions/FavoriteAction.vue' /* webpackChunkName: "components/new-product-actions-favorite-action" */).then(c => wrapFunctional(c.default || c))
export const NewProductActionsRequestButton = () => import('../../components/new-product/actions/RequestButton.vue' /* webpackChunkName: "components/new-product-actions-request-button" */).then(c => wrapFunctional(c.default || c))
export const NewProductActionsShareAction = () => import('../../components/new-product/actions/ShareAction.vue' /* webpackChunkName: "components/new-product-actions-share-action" */).then(c => wrapFunctional(c.default || c))
export const NewProductActionsSurveyAction = () => import('../../components/new-product/actions/SurveyAction.vue' /* webpackChunkName: "components/new-product-actions-survey-action" */).then(c => wrapFunctional(c.default || c))
export const NewProductCommonShareButton = () => import('../../components/new-product/common/ShareButton.vue' /* webpackChunkName: "components/new-product-common-share-button" */).then(c => wrapFunctional(c.default || c))
export const NewProductCurationList = () => import('../../components/new-product/curation/CurationList.vue' /* webpackChunkName: "components/new-product-curation-list" */).then(c => wrapFunctional(c.default || c))
export const NewProductCurationRecommendAgeClass = () => import('../../components/new-product/curation/RecommendAgeClass.vue' /* webpackChunkName: "components/new-product-curation-recommend-age-class" */).then(c => wrapFunctional(c.default || c))
export const NewProductCurationRelatedClassCuration = () => import('../../components/new-product/curation/RelatedClassCuration.vue' /* webpackChunkName: "components/new-product-curation-related-class-curation" */).then(c => wrapFunctional(c.default || c))
export const NewProductCurationSameCategoryCuration = () => import('../../components/new-product/curation/SameCategoryCuration.vue' /* webpackChunkName: "components/new-product-curation-same-category-curation" */).then(c => wrapFunctional(c.default || c))
export const NewProductCurationTeacherRelatedClass = () => import('../../components/new-product/curation/TeacherRelatedClass.vue' /* webpackChunkName: "components/new-product-curation-teacher-related-class" */).then(c => wrapFunctional(c.default || c))
export const NewProductGuideClassTypeGuide = () => import('../../components/new-product/guide/ClassTypeGuide.vue' /* webpackChunkName: "components/new-product-guide-class-type-guide" */).then(c => wrapFunctional(c.default || c))
export const NewProductNoticeClassNoticeAt = () => import('../../components/new-product/notice/ClassNoticeAt.vue' /* webpackChunkName: "components/new-product-notice-class-notice-at" */).then(c => wrapFunctional(c.default || c))
export const NewProductNoticeClassNoticeDesc = () => import('../../components/new-product/notice/ClassNoticeDesc.vue' /* webpackChunkName: "components/new-product-notice-class-notice-desc" */).then(c => wrapFunctional(c.default || c))
export const NewProductNoticeClassNoticeTeacherMeta = () => import('../../components/new-product/notice/ClassNoticeTeacherMeta.vue' /* webpackChunkName: "components/new-product-notice-class-notice-teacher-meta" */).then(c => wrapFunctional(c.default || c))
export const NewProductNoticeMobileNoticeDetailNav = () => import('../../components/new-product/notice/MobileNoticeDetailNav.vue' /* webpackChunkName: "components/new-product-notice-mobile-notice-detail-nav" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviews = () => import('../../components/new-product/reviews/ProductReviews.vue' /* webpackChunkName: "components/new-product-reviews" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviewsReviewAdmin = () => import('../../components/new-product/reviews/ReviewAdmin.vue' /* webpackChunkName: "components/new-product-reviews-review-admin" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviewsReviewContents = () => import('../../components/new-product/reviews/ReviewContents.vue' /* webpackChunkName: "components/new-product-reviews-review-contents" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviewsReviewHeader = () => import('../../components/new-product/reviews/ReviewHeader.vue' /* webpackChunkName: "components/new-product-reviews-review-header" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviewsReviewHelpful = () => import('../../components/new-product/reviews/ReviewHelpful.vue' /* webpackChunkName: "components/new-product-reviews-review-helpful" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviewsReviewItem = () => import('../../components/new-product/reviews/ReviewItem.vue' /* webpackChunkName: "components/new-product-reviews-review-item" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviewsReviewItemSkeleton = () => import('../../components/new-product/reviews/ReviewItemSkeleton.vue' /* webpackChunkName: "components/new-product-reviews-review-item-skeleton" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviewsReviewList = () => import('../../components/new-product/reviews/ReviewList.vue' /* webpackChunkName: "components/new-product-reviews-review-list" */).then(c => wrapFunctional(c.default || c))
export const NewProductReviewsReviewUser = () => import('../../components/new-product/reviews/ReviewUser.vue' /* webpackChunkName: "components/new-product-reviews-review-user" */).then(c => wrapFunctional(c.default || c))
export const NewProductSchedule = () => import('../../components/new-product/schedule/Schedule.vue' /* webpackChunkName: "components/new-product-schedule" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryGgugeDayEvent = () => import('../../components/new-product/summary/GgugeDayEvent.vue' /* webpackChunkName: "components/new-product-summary-gguge-day-event" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummary = () => import('../../components/new-product/summary/ProductSummary.vue' /* webpackChunkName: "components/new-product-summary" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryReviewInfo = () => import('../../components/new-product/summary/ReviewInfo.vue' /* webpackChunkName: "components/new-product-summary-review-info" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryReviewStars = () => import('../../components/new-product/summary/ReviewStars.vue' /* webpackChunkName: "components/new-product-summary-review-stars" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryInfo = () => import('../../components/new-product/summary/SummaryInfo.vue' /* webpackChunkName: "components/new-product-summary-info" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryTagList = () => import('../../components/new-product/summary/SummaryTagList.vue' /* webpackChunkName: "components/new-product-summary-tag-list" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryText = () => import('../../components/new-product/summary/SummaryText.vue' /* webpackChunkName: "components/new-product-summary-text" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherClassNotice = () => import('../../components/new-product/teacher/ClassNotice.vue' /* webpackChunkName: "components/new-product-teacher-class-notice" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherClassNoticeItem = () => import('../../components/new-product/teacher/ClassNoticeItem.vue' /* webpackChunkName: "components/new-product-teacher-class-notice-item" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherSendQuestionButton = () => import('../../components/new-product/teacher/SendQuestionButton.vue' /* webpackChunkName: "components/new-product-teacher-send-question-button" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherDescription = () => import('../../components/new-product/teacher/TeacherDescription.vue' /* webpackChunkName: "components/new-product-teacher-description" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherInfo = () => import('../../components/new-product/teacher/TeacherInfo.vue' /* webpackChunkName: "components/new-product-teacher-info" */).then(c => wrapFunctional(c.default || c))
export const NewProductThumbnailWrapper = () => import('../../components/new-product/thumbnail/ThumbnailWrapper.vue' /* webpackChunkName: "components/new-product-thumbnail-wrapper" */).then(c => wrapFunctional(c.default || c))
export const NewProductThumbnailVideoThumbnail = () => import('../../components/new-product/thumbnail/VideoThumbnail.vue' /* webpackChunkName: "components/new-product-thumbnail-video-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const PaymentEventCheckBox = () => import('../../components/payment/event/EventCheckBox.vue' /* webpackChunkName: "components/payment-event-check-box" */).then(c => wrapFunctional(c.default || c))
export const PaymentEvent = () => import('../../components/payment/event/PaymentEvent.vue' /* webpackChunkName: "components/payment-event" */).then(c => wrapFunctional(c.default || c))
export const PaymentScheduleGroupSchedule = () => import('../../components/payment/schedule/GroupSchedule.vue' /* webpackChunkName: "components/payment-schedule-group-schedule" */).then(c => wrapFunctional(c.default || c))
export const PaymentScheduleOnedaySchedule = () => import('../../components/payment/schedule/OnedaySchedule.vue' /* webpackChunkName: "components/payment-schedule-oneday-schedule" */).then(c => wrapFunctional(c.default || c))
export const PaymentScheduleSelectSchedule = () => import('../../components/payment/schedule/SelectSchedule.vue' /* webpackChunkName: "components/payment-schedule-select-schedule" */).then(c => wrapFunctional(c.default || c))
export const PaymentScheduleSelectScheduleSwiper = () => import('../../components/payment/schedule/SelectScheduleSwiper.vue' /* webpackChunkName: "components/payment-schedule-select-schedule-swiper" */).then(c => wrapFunctional(c.default || c))
export const PaymentScheduleSubsSchedule = () => import('../../components/payment/schedule/SubsSchedule.vue' /* webpackChunkName: "components/payment-schedule-subs-schedule" */).then(c => wrapFunctional(c.default || c))
export const PaymentScheduleSubscriptionDetail = () => import('../../components/payment/schedule/SubscriptionDetail.vue' /* webpackChunkName: "components/payment-schedule-subscription-detail" */).then(c => wrapFunctional(c.default || c))
export const ProductItemCumulativeCount = () => import('../../components/product-item/cumulative-count/CumulativeCount.vue' /* webpackChunkName: "components/product-item-cumulative-count" */).then(c => wrapFunctional(c.default || c))
export const ProductItemPriceInfo = () => import('../../components/product-item/price/PriceInfo.vue' /* webpackChunkName: "components/product-item-price-info" */).then(c => wrapFunctional(c.default || c))
export const ProductItemRatingAndFavorite = () => import('../../components/product-item/rating-favorite/RatingAndFavorite.vue' /* webpackChunkName: "components/product-item-rating-and-favorite" */).then(c => wrapFunctional(c.default || c))
export const ProductItemTagList = () => import('../../components/product-item/tag/TagList.vue' /* webpackChunkName: "components/product-item-tag-list" */).then(c => wrapFunctional(c.default || c))
export const ProductItemThumbnailDimFilter = () => import('../../components/product-item/thumbnail/DimFilter.vue' /* webpackChunkName: "components/product-item-thumbnail-dim-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductItemThumbnailFavoriteButton = () => import('../../components/product-item/thumbnail/FavoriteButton.vue' /* webpackChunkName: "components/product-item-thumbnail-favorite-button" */).then(c => wrapFunctional(c.default || c))
export const ProductItemThumbnailItem = () => import('../../components/product-item/thumbnail/ThumbnailItem.vue' /* webpackChunkName: "components/product-item-thumbnail-item" */).then(c => wrapFunctional(c.default || c))
export const ProductItemTitle = () => import('../../components/product-item/title/ProductItemTitle.vue' /* webpackChunkName: "components/product-item-title" */).then(c => wrapFunctional(c.default || c))
export const PsycheCommonIssuePsycheCouponCard = () => import('../../components/psyche/common/IssuePsycheCouponCard.vue' /* webpackChunkName: "components/psyche-common-issue-psyche-coupon-card" */).then(c => wrapFunctional(c.default || c))
export const PsycheAsyncModal = () => import('../../components/psyche/common/PsycheAsyncModal.vue' /* webpackChunkName: "components/psyche-async-modal" */).then(c => wrapFunctional(c.default || c))
export const PsycheCommonButton = () => import('../../components/psyche/common/PsycheCommonButton.vue' /* webpackChunkName: "components/psyche-common-button" */).then(c => wrapFunctional(c.default || c))
export const PsycheHeader = () => import('../../components/psyche/common/PsycheHeader.vue' /* webpackChunkName: "components/psyche-header" */).then(c => wrapFunctional(c.default || c))
export const PsycheThumbnail = () => import('../../components/psyche/common/PsycheThumbnail.vue' /* webpackChunkName: "components/psyche-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const PsycheVideoItem = () => import('../../components/psyche/common/PsycheVideoItem.vue' /* webpackChunkName: "components/psyche-video-item" */).then(c => wrapFunctional(c.default || c))
export const PsycheVideoList = () => import('../../components/psyche/common/PsycheVideoList.vue' /* webpackChunkName: "components/psyche-video-list" */).then(c => wrapFunctional(c.default || c))
export const PsycheDetail = () => import('../../components/psyche/detail/PsycheDetail.vue' /* webpackChunkName: "components/psyche-detail" */).then(c => wrapFunctional(c.default || c))
export const PsycheVideo = () => import('../../components/psyche/detail/PsycheVideo.vue' /* webpackChunkName: "components/psyche-video" */).then(c => wrapFunctional(c.default || c))
export const PsycheGuidanceDetailRecommendSection = () => import('../../components/psyche/guidance/GuidanceDetailRecommendSection.vue' /* webpackChunkName: "components/psyche-guidance-detail-recommend-section" */).then(c => wrapFunctional(c.default || c))
export const PsycheGuidanceHomeRecommendSection = () => import('../../components/psyche/guidance/GuidanceHomeRecommendSection.vue' /* webpackChunkName: "components/psyche-guidance-home-recommend-section" */).then(c => wrapFunctional(c.default || c))
export const PsycheGuidanceIntro = () => import('../../components/psyche/guidance/GuidanceIntro.vue' /* webpackChunkName: "components/psyche-guidance-intro" */).then(c => wrapFunctional(c.default || c))
export const PsycheGuidanceIntroVideo = () => import('../../components/psyche/guidance/GuidanceIntroVideo.vue' /* webpackChunkName: "components/psyche-guidance-intro-video" */).then(c => wrapFunctional(c.default || c))
export const PsycheGuidanceNotice = () => import('../../components/psyche/guidance/GuidanceNotice.vue' /* webpackChunkName: "components/psyche-guidance-notice" */).then(c => wrapFunctional(c.default || c))
export const PsycheGuidanceRecommendContainer = () => import('../../components/psyche/guidance/GuidanceRecommendContainer.vue' /* webpackChunkName: "components/psyche-guidance-recommend-container" */).then(c => wrapFunctional(c.default || c))
export const PsycheGuidanceRecommendItem = () => import('../../components/psyche/guidance/GuidanceRecommendItem.vue' /* webpackChunkName: "components/psyche-guidance-recommend-item" */).then(c => wrapFunctional(c.default || c))
export const PsycheHunoChipList = () => import('../../components/psyche/huno/ChipList.vue' /* webpackChunkName: "components/psyche-huno-chip-list" */).then(c => wrapFunctional(c.default || c))
export const PsycheHunoGgugeAndHuno = () => import('../../components/psyche/huno/GgugeAndHuno.vue' /* webpackChunkName: "components/psyche-huno-gguge-and-huno" */).then(c => wrapFunctional(c.default || c))
export const PsycheHunoHomeCuration = () => import('../../components/psyche/huno/HunoHomeCuration.vue' /* webpackChunkName: "components/psyche-huno-home-curation" */).then(c => wrapFunctional(c.default || c))
export const PsycheHunoIntro = () => import('../../components/psyche/huno/HunoIntro.vue' /* webpackChunkName: "components/psyche-huno-intro" */).then(c => wrapFunctional(c.default || c))
export const PsycheHunoRecommendSection = () => import('../../components/psyche/huno/HunoRecommendSection.vue' /* webpackChunkName: "components/psyche-huno-recommend-section" */).then(c => wrapFunctional(c.default || c))
export const PsycheHunoKeyChip = () => import('../../components/psyche/huno/KeyChip.vue' /* webpackChunkName: "components/psyche-huno-key-chip" */).then(c => wrapFunctional(c.default || c))
export const PsycheLogo = () => import('../../components/psyche/layout/PsycheLogo.vue' /* webpackChunkName: "components/psyche-logo" */).then(c => wrapFunctional(c.default || c))
export const PsycheNavigationBar = () => import('../../components/psyche/layout/PsycheNavigationBar.vue' /* webpackChunkName: "components/psyche-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const RankingHeaderFilters = () => import('../../components/ranking/headerFilters/HeaderFilters.vue' /* webpackChunkName: "components/ranking-header-filters" */).then(c => wrapFunctional(c.default || c))
export const RankingHeaderFiltersLearningTypeToggle = () => import('../../components/ranking/headerFilters/LearningTypeToggle.vue' /* webpackChunkName: "components/ranking-header-filters-learning-type-toggle" */).then(c => wrapFunctional(c.default || c))
export const RankingHeaderFiltersPeriodFilter = () => import('../../components/ranking/headerFilters/PeriodFilter.vue' /* webpackChunkName: "components/ranking-header-filters-period-filter" */).then(c => wrapFunctional(c.default || c))
export const RankingRankingSubListNextMainFilterButton = () => import('../../components/ranking/rankingSubList/NextMainFilterButton.vue' /* webpackChunkName: "components/ranking-ranking-sub-list-next-main-filter-button" */).then(c => wrapFunctional(c.default || c))
export const RankingSkeleton = () => import('../../components/ranking/rankingSubList/RankingSkeleton.vue' /* webpackChunkName: "components/ranking-skeleton" */).then(c => wrapFunctional(c.default || c))
export const RankingSubList = () => import('../../components/ranking/rankingSubList/RankingSubList.vue' /* webpackChunkName: "components/ranking-sub-list" */).then(c => wrapFunctional(c.default || c))
export const RankingSubWrapper = () => import('../../components/ranking/rankingSubList/RankingSubWrapper.vue' /* webpackChunkName: "components/ranking-sub-wrapper" */).then(c => wrapFunctional(c.default || c))
export const RankingStickyFilter = () => import('../../components/ranking/stickyFilter/StickyFilter.vue' /* webpackChunkName: "components/ranking-sticky-filter" */).then(c => wrapFunctional(c.default || c))
export const ScheduleCalendar = () => import('../../components/schedule/calendar/ScheduleCalendar.vue' /* webpackChunkName: "components/schedule-calendar" */).then(c => wrapFunctional(c.default || c))
export const ScheduleDateWrapper = () => import('../../components/schedule/calendar/ScheduleDateWrapper.vue' /* webpackChunkName: "components/schedule-date-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ScheduleList = () => import('../../components/schedule/calendar/ScheduleList.vue' /* webpackChunkName: "components/schedule-list" */).then(c => wrapFunctional(c.default || c))
export const ScheduleViewSelectTab = () => import('../../components/schedule/calendar/ScheduleViewSelectTab.vue' /* webpackChunkName: "components/schedule-view-select-tab" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedClassPriceFilter = () => import('../../components/search/detailed/ClassPriceFilter.vue' /* webpackChunkName: "components/search-detailed-class-price-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedClassTypeButton = () => import('../../components/search/detailed/ClassTypeButton.vue' /* webpackChunkName: "components/search-detailed-class-type-button" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedClassTypeFilter = () => import('../../components/search/detailed/ClassTypeFilter.vue' /* webpackChunkName: "components/search-detailed-class-type-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedFilterContainer = () => import('../../components/search/detailed/DetailedFilterContainer.vue' /* webpackChunkName: "components/search-detailed-filter-container" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedExpandableFilterSection = () => import('../../components/search/detailed/ExpandableFilterSection.vue' /* webpackChunkName: "components/search-detailed-expandable-filter-section" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedOrderFilter = () => import('../../components/search/detailed/OrderFilter.vue' /* webpackChunkName: "components/search-detailed-order-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedRecommendFilter = () => import('../../components/search/detailed/RecommendFilter.vue' /* webpackChunkName: "components/search-detailed-recommend-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterDatePicker = () => import('../../components/search/filter/FilterDatePicker.vue' /* webpackChunkName: "components/search-filter-date-picker" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterTextField = () => import('../../components/search/filter/FilterTextField.vue' /* webpackChunkName: "components/search-filter-text-field" */).then(c => wrapFunctional(c.default || c))
export const TeacherInfoCareer = () => import('../../components/teacher/info/Career.vue' /* webpackChunkName: "components/teacher-info-career" */).then(c => wrapFunctional(c.default || c))
export const TeacherInfoDescTooltip = () => import('../../components/teacher/info/DescTooltip.vue' /* webpackChunkName: "components/teacher-info-desc-tooltip" */).then(c => wrapFunctional(c.default || c))
export const TeacherInfoIntroduceImgList = () => import('../../components/teacher/info/IntroduceImgList.vue' /* webpackChunkName: "components/teacher-info-introduce-img-list" */).then(c => wrapFunctional(c.default || c))
export const TeacherInfoRequestPrivateTalkCallButton = () => import('../../components/teacher/info/RequestPrivateTalkCallButton.vue' /* webpackChunkName: "components/teacher-info-request-private-talk-call-button" */).then(c => wrapFunctional(c.default || c))
export const TeacherInfoSection = () => import('../../components/teacher/info/TeacherInfoSection.vue' /* webpackChunkName: "components/teacher-info-section" */).then(c => wrapFunctional(c.default || c))
export const TeacherIntro = () => import('../../components/teacher/info/TeacherIntro.vue' /* webpackChunkName: "components/teacher-intro" */).then(c => wrapFunctional(c.default || c))
export const TeacherLogSection = () => import('../../components/teacher/info/TeacherLogSection.vue' /* webpackChunkName: "components/teacher-log-section" */).then(c => wrapFunctional(c.default || c))
export const TeacherReviewList = () => import('../../components/teacher/review/TeacherReviewList.vue' /* webpackChunkName: "components/teacher-review-list" */).then(c => wrapFunctional(c.default || c))
export const TeacherReviewSection = () => import('../../components/teacher/review/TeacherReviewSection.vue' /* webpackChunkName: "components/teacher-review-section" */).then(c => wrapFunctional(c.default || c))
export const TeacherSchedule = () => import('../../components/teacher/schedule/TeacherSchedule.vue' /* webpackChunkName: "components/teacher-schedule" */).then(c => wrapFunctional(c.default || c))
export const CourseClassItem = () => import('../../components/course/related-class/course-item/CourseClassItem.vue' /* webpackChunkName: "components/course-class-item" */).then(c => wrapFunctional(c.default || c))
export const CourseItemTitle = () => import('../../components/course/related-class/course-item/CourseItemTitle.vue' /* webpackChunkName: "components/course-item-title" */).then(c => wrapFunctional(c.default || c))
export const CourseRelatedClassCourseItemGreatStamp = () => import('../../components/course/related-class/course-item/GreatStamp.vue' /* webpackChunkName: "components/course-related-class-course-item-great-stamp" */).then(c => wrapFunctional(c.default || c))
export const CourseRelatedClassCourseItemOrderOrFlag = () => import('../../components/course/related-class/course-item/OrderOrFlag.vue' /* webpackChunkName: "components/course-related-class-course-item-order-or-flag" */).then(c => wrapFunctional(c.default || c))
export const CourseRelatedClassCourseItemProfileAndRating = () => import('../../components/course/related-class/course-item/ProfileAndRating.vue' /* webpackChunkName: "components/course-related-class-course-item-profile-and-rating" */).then(c => wrapFunctional(c.default || c))
export const CourseRelatedClassCourseItemTagList = () => import('../../components/course/related-class/course-item/TagList.vue' /* webpackChunkName: "components/course-related-class-course-item-tag-list" */).then(c => wrapFunctional(c.default || c))
export const CourseRelatedClassCourseItemThumbnailItem = () => import('../../components/course/related-class/course-item/ThumbnailItem.vue' /* webpackChunkName: "components/course-related-class-course-item-thumbnail-item" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionItemClassInfo = () => import('../../components/my/subscription/item/ClassInfo.vue' /* webpackChunkName: "components/my-subscription-item-class-info" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionItemSubsDetail = () => import('../../components/my/subscription/item/SubsDetail.vue' /* webpackChunkName: "components/my-subscription-item-subs-detail" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionItemSubsInfo = () => import('../../components/my/subscription/item/SubsInfo.vue' /* webpackChunkName: "components/my-subscription-item-subs-info" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionItemSubsItem = () => import('../../components/my/subscription/item/SubsItem.vue' /* webpackChunkName: "components/my-subscription-item-subs-item" */).then(c => wrapFunctional(c.default || c))
export const MySubscriptionItemSubsList = () => import('../../components/my/subscription/item/SubsList.vue' /* webpackChunkName: "components/my-subscription-item-subs-list" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonEducationInfoItem = () => import('../../components/new-home/common/education-info/EducationInfoItem.vue' /* webpackChunkName: "components/new-home-common-education-info-item" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonEducationInfoSection = () => import('../../components/new-home/common/education-info/EducationInfoSection.vue' /* webpackChunkName: "components/new-home-common-education-info-section" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonHeroBanner = () => import('../../components/new-home/common/hero-banner/HeroBanner.vue' /* webpackChunkName: "components/new-home-common-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonHeroBannerSkeleton = () => import('../../components/new-home/common/hero-banner/HeroBannerSkeleton.vue' /* webpackChunkName: "components/new-home-common-hero-banner-skeleton" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonHeroBannerSlide = () => import('../../components/new-home/common/hero-banner/HeroBannerSlide.vue' /* webpackChunkName: "components/new-home-common-hero-banner-slide" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonHeroBannerSwiper = () => import('../../components/new-home/common/hero-banner/HeroBannerSwiper.vue' /* webpackChunkName: "components/new-home-common-hero-banner-swiper" */).then(c => wrapFunctional(c.default || c))
export const NewClassSection = () => import('../../components/new-home/common/new-class-section/NewClassSection.vue' /* webpackChunkName: "components/new-class-section" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonOngoingCourses = () => import('../../components/new-home/common/ongoing-courses/OngoingCourses.vue' /* webpackChunkName: "components/new-home-common-ongoing-courses" */).then(c => wrapFunctional(c.default || c))
export const NewHomeRanking = () => import('../../components/new-home/common/ranking/HomeRanking.vue' /* webpackChunkName: "components/new-home-ranking" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingCurationSkeleton = () => import('../../components/new-home/common/ranking/RankingCurationSkeleton.vue' /* webpackChunkName: "components/new-home-common-ranking-curation-skeleton" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingCurationWrapper = () => import('../../components/new-home/common/ranking/RankingCurationWrapper.vue' /* webpackChunkName: "components/new-home-common-ranking-curation-wrapper" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingHeader = () => import('../../components/new-home/common/ranking/RankingHeader.vue' /* webpackChunkName: "components/new-home-common-ranking-header" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingList = () => import('../../components/new-home/common/ranking/RankingList.vue' /* webpackChunkName: "components/new-home-common-ranking-list" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingMoreButton = () => import('../../components/new-home/common/ranking/RankingMoreButton.vue' /* webpackChunkName: "components/new-home-common-ranking-more-button" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingTabTitle = () => import('../../components/new-home/common/ranking/RankingTabTitle.vue' /* webpackChunkName: "components/new-home-common-ranking-tab-title" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonSurveyCuration = () => import('../../components/new-home/common/survey/SurveyCuration.vue' /* webpackChunkName: "components/new-home-common-survey-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonTeacherCuration = () => import('../../components/new-home/common/teacher/TeacherCuration.vue' /* webpackChunkName: "components/new-home-common-teacher-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonTeacherCurationSkeleton = () => import('../../components/new-home/common/teacher/TeacherCurationSkeleton.vue' /* webpackChunkName: "components/new-home-common-teacher-curation-skeleton" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonTeacherItem = () => import('../../components/new-home/common/teacher/TeacherItem.vue' /* webpackChunkName: "components/new-home-common-teacher-item" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonTeacherProfileImg = () => import('../../components/new-home/common/teacher/TeacherProfileImg.vue' /* webpackChunkName: "components/new-home-common-teacher-profile-img" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonTeacherTagList = () => import('../../components/new-home/common/teacher/TeacherTagList.vue' /* webpackChunkName: "components/new-home-common-teacher-tag-list" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonUpcoming = () => import('../../components/new-home/common/upcoming/Upcoming.vue' /* webpackChunkName: "components/new-home-common-upcoming" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonUpcomingClassStartTimer = () => import('../../components/new-home/common/upcoming/UpcomingClassStartTimer.vue' /* webpackChunkName: "components/new-home-common-upcoming-class-start-timer" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationCategoryItem = () => import('../../components/new-home/education/category/CategoryItem.vue' /* webpackChunkName: "components/new-home-education-category-item" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationCategory = () => import('../../components/new-home/education/category/EducationCategory.vue' /* webpackChunkName: "components/new-home-education-category" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationCourseCuration = () => import('../../components/new-home/education/course-curation/EducationCourseCuration.vue' /* webpackChunkName: "components/new-home-education-course-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationCurriculumCurationLongCurriculumCuration = () => import('../../components/new-home/education/curriculum-curation/LongCurriculumCuration.vue' /* webpackChunkName: "components/new-home-education-curriculum-curation-long-curriculum-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationCurriculumCurationShortCurriculumCuration = () => import('../../components/new-home/education/curriculum-curation/ShortCurriculumCuration.vue' /* webpackChunkName: "components/new-home-education-curriculum-curation-short-curriculum-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationFixedCurationFirstFixedCuration = () => import('../../components/new-home/education/fixed-curation/FirstFixedCuration.vue' /* webpackChunkName: "components/new-home-education-fixed-curation-first-fixed-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationFixedCuration = () => import('../../components/new-home/education/fixed-curation/FixedCuration.vue' /* webpackChunkName: "components/new-home-education-fixed-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationGradeCurriculumByGrade = () => import('../../components/new-home/education/grade/CurriculumByGrade.vue' /* webpackChunkName: "components/new-home-education-grade-curriculum-by-grade" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationGradeItem = () => import('../../components/new-home/education/grade/GradeItem.vue' /* webpackChunkName: "components/new-home-education-grade-item" */).then(c => wrapFunctional(c.default || c))
export const NewHomeEducationTeacher = () => import('../../components/new-home/education/teacher/Teacher.vue' /* webpackChunkName: "components/new-home-education-teacher" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentFixedCuration = () => import('../../components/new-home/talent/fixed-curation/FixedCuration.vue' /* webpackChunkName: "components/new-home-talent-fixed-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentMostPurchased = () => import('../../components/new-home/talent/most-purchased/MostPurchased.vue' /* webpackChunkName: "components/new-home-talent-most-purchased" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentReviewCuration = () => import('../../components/new-home/talent/review/ReviewCuration.vue' /* webpackChunkName: "components/new-home-talent-review-curation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentReviewItem = () => import('../../components/new-home/talent/review/ReviewItem.vue' /* webpackChunkName: "components/new-home-talent-review-item" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentTeacher = () => import('../../components/new-home/talent/teacher/Teacher.vue' /* webpackChunkName: "components/new-home-talent-teacher" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentTrending = () => import('../../components/new-home/talent/trending/Trending.vue' /* webpackChunkName: "components/new-home-talent-trending" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentTrendingItem = () => import('../../components/new-home/talent/trending/TrendingItem.vue' /* webpackChunkName: "components/new-home-talent-trending-item" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentTrendingList = () => import('../../components/new-home/talent/trending/TrendingList.vue' /* webpackChunkName: "components/new-home-talent-trending-list" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentTrendingSkeleton = () => import('../../components/new-home/talent/trending/TrendingSkeleton.vue' /* webpackChunkName: "components/new-home-talent-trending-skeleton" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentTrendingThumbnail = () => import('../../components/new-home/talent/trending/TrendingThumbnail.vue' /* webpackChunkName: "components/new-home-talent-trending-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentTrendingCategory = () => import('../../components/new-home/talent/trending-category/TrendingCategory.vue' /* webpackChunkName: "components/new-home-talent-trending-category" */).then(c => wrapFunctional(c.default || c))
export const NewHomeTalentTrendingCategoryItem = () => import('../../components/new-home/talent/trending-category/TrendingCategoryItem.vue' /* webpackChunkName: "components/new-home-talent-trending-category-item" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleCommonCurriculumItem = () => import('../../components/new-product/schedule/common/CurriculumItem.vue' /* webpackChunkName: "components/new-product-schedule-common-curriculum-item" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleCommonOverlappedScheduleModal = () => import('../../components/new-product/schedule/common/OverlappedScheduleModal.vue' /* webpackChunkName: "components/new-product-schedule-common-overlapped-schedule-modal" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleCommonOverlappedScheduleNudge = () => import('../../components/new-product/schedule/common/OverlappedScheduleNudge.vue' /* webpackChunkName: "components/new-product-schedule-common-overlapped-schedule-nudge" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleItem = () => import('../../components/new-product/schedule/common/ProductScheduleItem.vue' /* webpackChunkName: "components/new-product-schedule-item" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleAlarm = () => import('../../components/new-product/schedule/common/ScheduleAlarm.vue' /* webpackChunkName: "components/new-product-schedule-alarm" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleCommonSearchScheduleButton = () => import('../../components/new-product/schedule/common/SearchScheduleButton.vue' /* webpackChunkName: "components/new-product-schedule-common-search-schedule-button" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleCommonSearchSimilarScheduleButton = () => import('../../components/new-product/schedule/common/SearchSimilarScheduleButton.vue' /* webpackChunkName: "components/new-product-schedule-common-search-similar-schedule-button" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleCommonSelectScheduleSwiper = () => import('../../components/new-product/schedule/common/SelectScheduleSwiper.vue' /* webpackChunkName: "components/new-product-schedule-common-select-schedule-swiper" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleGroupCurriculum = () => import('../../components/new-product/schedule/group/GroupCurriculum.vue' /* webpackChunkName: "components/new-product-schedule-group-curriculum" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleGroupScheduleDetailItem = () => import('../../components/new-product/schedule/group/GroupScheduleDetailItem.vue' /* webpackChunkName: "components/new-product-schedule-group-schedule-detail-item" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleGroupScheduleSelect = () => import('../../components/new-product/schedule/group/GroupScheduleSelect.vue' /* webpackChunkName: "components/new-product-schedule-group-schedule-select" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleOnedayScheduleSelect = () => import('../../components/new-product/schedule/oneday/OnedayScheduleSelect.vue' /* webpackChunkName: "components/new-product-schedule-oneday-schedule-select" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleSubscribeSingleCurriculum = () => import('../../components/new-product/schedule/subscribe/SingleCurriculum.vue' /* webpackChunkName: "components/new-product-schedule-subscribe-single-curriculum" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleSubscribeSubsScheduleSelect = () => import('../../components/new-product/schedule/subscribe/SubsScheduleSelect.vue' /* webpackChunkName: "components/new-product-schedule-subscribe-subs-schedule-select" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleSubscribeCurriculum = () => import('../../components/new-product/schedule/subscribe/SubscribeCurriculum.vue' /* webpackChunkName: "components/new-product-schedule-subscribe-curriculum" */).then(c => wrapFunctional(c.default || c))
export const NewProductScheduleSubscribeSubscriptionData = () => import('../../components/new-product/schedule/subscribe/SubscriptionData.vue' /* webpackChunkName: "components/new-product-schedule-subscribe-subscription-data" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailClassAge = () => import('../../components/new-product/summary/detail/ClassAge.vue' /* webpackChunkName: "components/new-product-summary-detail-class-age" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailClassAgeTooltip = () => import('../../components/new-product/summary/detail/ClassAgeTooltip.vue' /* webpackChunkName: "components/new-product-summary-detail-class-age-tooltip" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailClassTime = () => import('../../components/new-product/summary/detail/ClassTime.vue' /* webpackChunkName: "components/new-product-summary-detail-class-time" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailClassType = () => import('../../components/new-product/summary/detail/ClassType.vue' /* webpackChunkName: "components/new-product-summary-detail-class-type" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailClassTypeTooltip = () => import('../../components/new-product/summary/detail/ClassTypeTooltip.vue' /* webpackChunkName: "components/new-product-summary-detail-class-type-tooltip" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailMaxUser = () => import('../../components/new-product/summary/detail/MaxUser.vue' /* webpackChunkName: "components/new-product-summary-detail-max-user" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailRecordingModal = () => import('../../components/new-product/summary/detail/RecordingModal.vue' /* webpackChunkName: "components/new-product-summary-detail-recording-modal" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailRecordingSupported = () => import('../../components/new-product/summary/detail/RecordingSupported.vue' /* webpackChunkName: "components/new-product-summary-detail-recording-supported" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetailShipping = () => import('../../components/new-product/summary/detail/Shipping.vue' /* webpackChunkName: "components/new-product-summary-detail-shipping" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryDetail = () => import('../../components/new-product/summary/detail/SummaryDetail.vue' /* webpackChunkName: "components/new-product-summary-detail" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryPriceBundlePrice = () => import('../../components/new-product/summary/price/BundlePrice.vue' /* webpackChunkName: "components/new-product-summary-price-bundle-price" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryPriceNonLoginPrice = () => import('../../components/new-product/summary/price/NonLoginPrice.vue' /* webpackChunkName: "components/new-product-summary-price-non-login-price" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryPriceOnedayPrice = () => import('../../components/new-product/summary/price/OnedayPrice.vue' /* webpackChunkName: "components/new-product-summary-price-oneday-price" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryPrice = () => import('../../components/new-product/summary/price/Price.vue' /* webpackChunkName: "components/new-product-summary-price" */).then(c => wrapFunctional(c.default || c))
export const NewProductSummaryPriceSubscribePrice = () => import('../../components/new-product/summary/price/SubscribePrice.vue' /* webpackChunkName: "components/new-product-summary-price-subscribe-price" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherInfoIntroduceImgList = () => import('../../components/new-product/teacher/info/IntroduceImgList.vue' /* webpackChunkName: "components/new-product-teacher-info-introduce-img-list" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherAskButton = () => import('../../components/new-product/teacher/info/TeacherAskButton.vue' /* webpackChunkName: "components/new-product-teacher-ask-button" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherCareer = () => import('../../components/new-product/teacher/info/TeacherCareer.vue' /* webpackChunkName: "components/new-product-teacher-career" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherImages = () => import('../../components/new-product/teacher/info/TeacherImages.vue' /* webpackChunkName: "components/new-product-teacher-images" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherIntroduction = () => import('../../components/new-product/teacher/info/TeacherIntroduction.vue' /* webpackChunkName: "components/new-product-teacher-introduction" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherMoreInfoButton = () => import('../../components/new-product/teacher/info/TeacherMoreInfoButton.vue' /* webpackChunkName: "components/new-product-teacher-more-info-button" */).then(c => wrapFunctional(c.default || c))
export const NewProductTeacherSummaryInfo = () => import('../../components/new-product/teacher/info/TeacherSummaryInfo.vue' /* webpackChunkName: "components/new-product-teacher-summary-info" */).then(c => wrapFunctional(c.default || c))
export const RankingMainFilter = () => import('../../components/ranking/stickyFilter/mainFilter/RankingMainFilter.vue' /* webpackChunkName: "components/ranking-main-filter" */).then(c => wrapFunctional(c.default || c))
export const RankingSubFilter = () => import('../../components/ranking/stickyFilter/subFilter/RankingSubFilter.vue' /* webpackChunkName: "components/ranking-sub-filter" */).then(c => wrapFunctional(c.default || c))
export const RankingStickyFilterSubFilterItem = () => import('../../components/ranking/stickyFilter/subFilter/SubFilterItem.vue' /* webpackChunkName: "components/ranking-sticky-filter-sub-filter-item" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedCategoryDifficultyFilter = () => import('../../components/search/detailed/category/CategoryDifficultyFilter.vue' /* webpackChunkName: "components/search-detailed-category-difficulty-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchDetailedCategoryFilter = () => import('../../components/search/detailed/category/CategoryFilter.vue' /* webpackChunkName: "components/search-detailed-category-filter" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonAgeFilter = () => import('../../components/teacher/schedule/common/AgeFilter.vue' /* webpackChunkName: "components/teacher-schedule-common-age-filter" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonCalendarDay = () => import('../../components/teacher/schedule/common/CalendarDay.vue' /* webpackChunkName: "components/teacher-schedule-common-calendar-day" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonCalendarDayEventWrapper = () => import('../../components/teacher/schedule/common/CalendarDayEventWrapper.vue' /* webpackChunkName: "components/teacher-schedule-common-calendar-day-event-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonCalendarDayHeaderItem = () => import('../../components/teacher/schedule/common/CalendarDayHeaderItem.vue' /* webpackChunkName: "components/teacher-schedule-common-calendar-day-header-item" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonCalendarEventItem = () => import('../../components/teacher/schedule/common/CalendarEventItem.vue' /* webpackChunkName: "components/teacher-schedule-common-calendar-event-item" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonCalendarFilterList = () => import('../../components/teacher/schedule/common/CalendarFilterList.vue' /* webpackChunkName: "components/teacher-schedule-common-calendar-filter-list" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonCalendarTimeHeader = () => import('../../components/teacher/schedule/common/CalendarTimeHeader.vue' /* webpackChunkName: "components/teacher-schedule-common-calendar-time-header" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonClassTypeFilter = () => import('../../components/teacher/schedule/common/ClassTypeFilter.vue' /* webpackChunkName: "components/teacher-schedule-common-class-type-filter" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleCommonPurchasable = () => import('../../components/teacher/schedule/common/Purchasable.vue' /* webpackChunkName: "components/teacher-schedule-common-purchasable" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleDesktopCalendarDayHeader = () => import('../../components/teacher/schedule/desktop/DesktopCalendarDayHeader.vue' /* webpackChunkName: "components/teacher-schedule-desktop-calendar-day-header" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleDesktopCalendarNavigation = () => import('../../components/teacher/schedule/desktop/DesktopCalendarNavigation.vue' /* webpackChunkName: "components/teacher-schedule-desktop-calendar-navigation" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleDesktopCalendarToolbar = () => import('../../components/teacher/schedule/desktop/DesktopCalendarToolbar.vue' /* webpackChunkName: "components/teacher-schedule-desktop-calendar-toolbar" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleDesktopCalendarWeek = () => import('../../components/teacher/schedule/desktop/DesktopCalendarWeek.vue' /* webpackChunkName: "components/teacher-schedule-desktop-calendar-week" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleDesktopCalendarWeekList = () => import('../../components/teacher/schedule/desktop/DesktopCalendarWeekList.vue' /* webpackChunkName: "components/teacher-schedule-desktop-calendar-week-list" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleDesktopScheduleCalendar = () => import('../../components/teacher/schedule/desktop/DesktopScheduleCalendar.vue' /* webpackChunkName: "components/teacher-schedule-desktop-schedule-calendar" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleDesktopScheduleContents = () => import('../../components/teacher/schedule/desktop/DesktopScheduleContents.vue' /* webpackChunkName: "components/teacher-schedule-desktop-schedule-contents" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleMobileDayHeaderSwiper = () => import('../../components/teacher/schedule/mobile/MobileDayHeaderSwiper.vue' /* webpackChunkName: "components/teacher-schedule-mobile-day-header-swiper" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleMobileDayHeaderWrapper = () => import('../../components/teacher/schedule/mobile/MobileDayHeaderWrapper.vue' /* webpackChunkName: "components/teacher-schedule-mobile-day-header-wrapper" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleMobileScheduleSwiper = () => import('../../components/teacher/schedule/mobile/MobileScheduleSwiper.vue' /* webpackChunkName: "components/teacher-schedule-mobile-schedule-swiper" */).then(c => wrapFunctional(c.default || c))
export const TeacherScheduleMobileWeekSlide = () => import('../../components/teacher/schedule/mobile/MobileWeekSlide.vue' /* webpackChunkName: "components/teacher-schedule-mobile-week-slide" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingCurationDesktop = () => import('../../components/new-home/common/ranking/desktop/RankingCurationDesktop.vue' /* webpackChunkName: "components/new-home-common-ranking-curation-desktop" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingMobileRankingSwiperNavigation = () => import('../../components/new-home/common/ranking/mobile/MobileRankingSwiperNavigation.vue' /* webpackChunkName: "components/new-home-common-ranking-mobile-ranking-swiper-navigation" */).then(c => wrapFunctional(c.default || c))
export const NewHomeCommonRankingCurationMobile = () => import('../../components/new-home/common/ranking/mobile/RankingCurationMobile.vue' /* webpackChunkName: "components/new-home-common-ranking-curation-mobile" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
