import { render, staticRenderFns } from "./ThumbnailItem.vue?vue&type=template&id=640fa2b6&scoped=true"
import script from "./ThumbnailItem.vue?vue&type=script&lang=js"
export * from "./ThumbnailItem.vue?vue&type=script&lang=js"
import style0 from "./ThumbnailItem.vue?vue&type=style&index=0&id=640fa2b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "640fa2b6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CourseFavoriteButton: require('/codebuild/output/src3377776947/src/components/course/course-item/CourseFavoriteButton.vue').default,CourseTag: require('/codebuild/output/src3377776947/src/components/course/course-item/CourseTag.vue').default})
