
/**
 * @description
 * 24/08/22 기준 스케줄&홈 화면에서 쓰이는 떠있는 버튼
 */

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'FloatButton',
  components: { Icon },
  props: {
    icon: { type: String, required: true },
  },
};
